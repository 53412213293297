import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import { connect } from 'react-redux';
import Table from 'components/Table/Table.jsx';
import classNames from 'classnames';
import ParkSelector from 'components/ParkSelector/ParkSelector';
import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import LoginPage from 'views/Login/Oops.jsx';
import {
  get_generation_data,
  BULK_LOAD_PERIOD,
  resetGenerationDataAllLoaded,
} from 'actions/index';
import {
  getDefaultPark,
  buildAPIRequest,
  security_fetch_params,
  getMarkets,
  get_markets_data,
} from 'actions/index';
import { makeSeries, stocksChartOptions } from 'variables/charts';
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';
import { calculateWAPE } from 'utils/kpi';
import {logout} from 'utils/auth';
import { downloadParks, getActualCapacity } from 'utils/getDataMethods';
import alertify from "alertifyjs";

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  container: {
    minHeight: '500px',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  sizeS: {
    left: 290,
    top: '1.75rem',
    width: 'auto',
    height: 'auto',
  },
  stickyCol: {
    position: "sticky",
    left: 0,
    background: "white",
    textAlign: "center",
  },
  greenBackground: {
    background: '#ACE1AF',
  },
  redBackground: {
    background: "#CD5C5C",
  },
  orangeBackground: {
    background: "#FAD5A5",
  },
  blueBackground: {
    background: "#7CB9E8",
  },
};

const headerStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 2,
  color: primaryColor,
  fontSize: '1em',
  padding: '12px 0',
  textAlign: 'center',
  fontWeight: 500,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    forecast: state.generation.forecast,
    latest_forecast: state.generation.latest_forecast,
    power: state.generation.power,
    report_productive: state.generation.report_productive,
    loading: state.generation.loading,
    loadingChunks: state.generation.loadingChunks,
    allDataLoaded: state.generation.allDataLoaded,
    markets: state.markets.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_generation_data: (data) => dispatch(get_generation_data(data)),
  resetGenerationDataAllLoaded: (data) =>
    dispatch(resetGenerationDataAllLoaded(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

const ALL_DATA_ORDER = 5; // order in Highcharts options
const DATE_FORMAT_STR = 'YYYYMMDD';
const DAYS_BEFORE = 92;
const DAYS_AFTER = 2;

class Forecast extends React.Component {
  constructor(props) {
    super(props);
    const park = getDefaultPark() || { id: -1, capacity: null };
    this.state = {
      park: park,
      dateFrom: moment().add(-DAYS_BEFORE, 'days'),
      // "end" marks interval as "exclusive" in API,
      // so we have to add 2 days to get data to tomorrow
      dateTo: moment().add(DAYS_AFTER, 'days'),
      selectedPeriod: 1, // 1 means "3 months" in Chart Zoom controls
      capacity: [],
      market: {},
      maxDate: moment().add(DAYS_AFTER, 'days'),
      showNonActive: false,
      parks: [],
    };

    this.on_park_select = this.on_park_select.bind(this);
    this.update_windpark = this.update_windpark.bind(this);
    this.get_windpark_data = this.get_windpark_data.bind(this);
    this.getData = this.getData.bind(this);
    this.formatSeries = this.formatSeries.bind(this);
    this.calculateWAPEs = this.calculateWAPEs.bind(this);

    this.chartRef = React.createRef();
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart, 'beforeShowResetZoom');
  }

  getParks = async () => {
    const parks = await downloadParks(null, null, this.state.showNonActive);
    this.setState({ parks: parks.data });
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.get_windpark_data(this.state.park.id);
      this.getData(this.state.park.id);
      this.props.getMarkets();
      this.getParks(null, null, this.state.showNonActive)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showNonActive !== prevState.showNonActive) this.getParks(null, null, this.state.showNonActive)
    // For some reason HighCharts lib doesn't make active selected period
    // button after data was loaded. Maybe, because of re-render
    if (
      ((!this.props.loadingChunks && prevProps.loadingChunks) ||
        (!this.props.loading &&
          !this.props.loadingChunks &&
          prevProps.loading)) &&
      this.chartRef.current?.chart?.hasRendered
    ) {
      const { buttons } = this.chartRef.current.chart.rangeSelector;
      buttons[this.state.selectedPeriod].element.hcEvents.click[0].fn();
    }

    if (this.props.markets.length && !prevProps.markets.length) {
      const [market] = this.props.markets.filter(
        (market) => market.name === this.state.park.market
      );
      this.setState({ market });
    }

    if (prevState.park && this.state.park.name !== prevState.park.name) {
      const [market] = this.props.markets.filter(
        (market) => market.name === this.state.park.market.name
      );
      this.setState({ market });
    }

    if (this.props.report_productive !== prevProps.report_productive) {
      this.getCapacitySeriesData();
    }

    if (
      this.props.power.length &&
      JSON.stringify(prevProps.power) !== JSON.stringify(this.props.power)
    ) {
      this.findMaxDate(this.props.power);
    }
  }

  findMaxDate = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
      if (arr[i][1] !== arr[i - 1][1]) {
        return this.setState({ maxDate: moment(arr[i - 1][0]) });
      }
    }
    return null;
  };

  getCapacitySeriesData = async () => {
    const data = await getActualCapacity(
      this.props.report_productive, 
      this.state.park.id, 
      moment.tz(`${this.state.dateFrom.format('YYYY-MM-DD')} 00:00`, 'UTC'),
      moment.tz(
        `${moment().add(DAYS_AFTER, 'days').format('YYYY-MM-DD')} 24:00`,
        'UTC'
      ),
      this.props.conn.label
    )

    this.setState({ capacity: data });
  };

  getData(parkId, isDownloadingByChunks = false) {
    const { dateFrom, dateTo, park } = this.state;
    const params = {
      windpark: typeof parkId === 'number' ? parkId : park.id,
      dateFrom: dateFrom.format(DATE_FORMAT_STR),
      dateTo: dateTo.format(DATE_FORMAT_STR),
      isDownloadingByChunks,
    };
    this.props.get_generation_data(params);
  }

  on_park_select(_, value) {
    if (!value) return;

    // Reset state to initial and reset allDataLoaded flag
    this.props.resetGenerationDataAllLoaded();
    this.setState({
      dateFrom: moment().add(-DAYS_BEFORE, 'days'),
      dateTo: moment().add(DAYS_AFTER, 'days'),
      selectedPeriod: 1,
    });
    this.getData(value.id);
    this.get_windpark_data(value.id);
  }

  update_windpark(park_id) {
    const [url, headers] = buildAPIRequest('/api/v1/parks/' + park_id);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }
            data.updated_at = moment().unix();
            localForage.setItem(
              `fc_park_data_${park_id}_${this.props.conn.label}`,
              data,
              (err) => {
                // if err is non-null, we got an error
                if (err) {
                  console.log(err);
                }
              }
            );
            if (!data.data) {
              return;
            }
            this.setState({
              park: data.data,
            });
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get_windpark_data(park_id) {
    localForage.getItem(
      `fc_park_data_${park_id}_${this.props.conn.label}`,
      (err, value) => {
        if (value !== null) {
          if (value.updated_at < moment().unix() - 100) {
            this.update_windpark(park_id);
          } else {
            if (!value.data) {
              return;
            }
            this.setState({ park: value.data });
          }
        } else {
          this.update_windpark(park_id);
        }
      }
    );
  }

  // Format API output to [{ time: timestamp, value: Number }] format
  formatSeries(series) {
    return series.map((item) => ({ time: item[0], value: item[1] }));
  }

  // WAPE calculation
  calculateWAPEs(min, max, capacity) {
    const power = this.formatSeries(this.props.power);
    const forecast = this.formatSeries(this.props.forecast);
    const latestForecast = this.formatSeries(this.props.latest_forecast);
    let newForecast = [];
    if(capacity.length) {
      const zeroCapacityElements = capacity?.[0]?.data
        .filter(item => item[1] === 0)
        .map(item => item[0]);
      
      // if there are zero capacity elements in the capacity data, 
      // we need to set to zero them in the forecast data
      // to calculate the right MAPE
      forecast.forEach(item => {
        if(zeroCapacityElements.includes(item.time)) {
          newForecast.push({time: item.time, value: 0});
        } else 
          newForecast.push(item);
      });
    } 

    

    const wapeForecast11AM = calculateWAPE(power, newForecast, min, max);

    const wapeLatestForecast = calculateWAPE(power, latestForecast, min, max);

    return {
      'Production, MW': NaN,
      'Forecast 11AM, MW': wapeForecast11AM,
      'Forecast latest, MW': wapeLatestForecast,
      'Actual Capacity': NaN,
    };
  }

  avColumns = () => {
    const columns = [];
    columns.push({
      title: 'Delivery',
      field: 'date',
      sorting: false,
      cellStyle: {
        backgroundColor: 'white',
        position: 'sticky',
        left: 0,
        zIndex: 1,
        color: primaryColor,
      },
      headerStyle: {
        left: '0px',
        zIndex: 3,
      },
    });
    for (let i = 0; i < 24; i++) {
      columns.push({
        title: i.toString(),
        field: i.toString(),
        sorting: false,
      });
    }
    return columns;
  };

  calcAvailabilities = (capacityData) => {
    const dataObj = {};
    const granularity = 3600000 / (capacityData[1][0] - capacityData[0][0]);
    let date = moment.unix(capacityData[0][0] / 1000).tz('UTC');
    let iter = 0;
    for (let index = 0; index < capacityData.length; index++) {
      if (!(date.format('YYYY-MM-DD') in dataObj)) {
        iter = 0;
        dataObj[date.format('YYYY-MM-DD')] = {};
      } else {
        iter++;
      }
      dataObj[date.format('YYYY-MM-DD')][iter] =
        ((capacityData[index][1] / this.state.park.capacity) * 100).toFixed(0) + '%';
      date.add(granularity, 'hours');
    }
    const dataArr = [];
    Object.keys(dataObj).forEach((day) => {
      const obj = { ...dataObj[day], date: day };
      dataArr.push(obj);
    });
    return dataArr;
  };

  renderTableRow = (data) => {
    const period = { ...data };
    delete period.tableData;
    const getColor = (value) => {
      if(value === 100) return this.props.classes.greenBackground;
      else if(value === 0) return this.props.classes.redBackground;
      else if(value <= 50) return this.props.classes.orangeBackground;
      else return this.props.classes.blueBackground;
    };
    return (
      <TableRow hover key={data.tableData.id} id={data.tableData.id}>
        <TableCell
          className = {this.props.classes.stickyCol}
          align="center"
        >
          {period['date']}
        </TableCell>
        {Object.keys(period).filter(el => el !== 'date').map((key, id) => {
          return (
          <TableCell
            key={id}
            align="center"
            className = {getColor(Number(period[key].slice(0, -1)))}
          >
            {period[key]}
          </TableCell>
          )
        })}
      </TableRow>
    );
  };

  exportFunc = (cols, datas) => {
    const colsExport = cols.map((col) => ({
        ...col,
        title: col.titleExport ? col.titleExport : col.title,
    }));
    return ExportCsv(colsExport, datas, 'HourlyUnavailability');
  };

  handleChangeNonActiveParks = (_, value) => {
    this.setState({ showNonActive: value });
  }

  render() {
    const that = this;

    const handleTimePeriodClick = function () {
      const dateFrom =
        this.type === 'ytd'
          ? moment(`${new Date().getFullYear()}-01-01`)
          : moment().add(-this.count, this.type);

      let state = { selectedPeriod: this.order };
      let callback;
      if (that.state.dateFrom > dateFrom && !that.props.allDataLoaded) {
        callback = that.getData;
        state = {
          ...state,
          dateFrom,
          dateTo: moment().add(DAYS_AFTER, 'days'),
        };
      }
      that.setState(state, callback);
      return true;
    };

    const handleTimePeriodAllClick = function () {
      const dateFrom = moment().add(-BULK_LOAD_PERIOD, 'months');
      if (!that.props.loadingChunks && !that.props.allDataLoaded) {
        that.setState(
          {
            dateFrom,
            dateTo: moment().add(DAYS_AFTER, 'days'),
            selectedPeriod: this.order,
          },
          () => {
            that.getData(that.state.park?.id, true);
          }
        );
      }
      return true;
    };

    let avData = {};
    let renderCapacitySeries = [];
    let convertData = [];
    if (this.state.capacity.length) {
      convertData = this.state.capacity[0].data
        .filter((el) => el[1] !== null)
        .map((x) => [x[0], x[1] / 1000]);
      renderCapacitySeries = [
        makeSeries(`${this.state.capacity[0].label} Capacity`, convertData, {
          legendIndex: 2,
        }),
      ];
      if (convertData.length) {
        avData = this.calcAvailabilities(convertData);
      }
    }

    const { classes } = this.props;
    const tableHead = ['id', 'name', 'capacity, kW', 'last production, UTC'];
    let tableData = [];
    if (this.props.report_productive) {
      tableData = this.props.report_productive
        .sort((a, b) =>
          a.time > b.time
            ? -1
            : a.time === b.time
            ? a.capacity > b.capacity
              ? -1
              : 1
            : 1
        )
        .map((x) => {
          return [
            x.id,
            x.name,
            window.numberWithCommas(x.capacity),
            moment(x.time).format('Y-M-D HH:mm:ss'),
          ];
        });
    }

    const minX = this.state.dateFrom.unix() * 1000;
    const maxX = this.state.maxDate.unix() * 1000;
    const wapes = this.calculateWAPEs(minX, maxX, this.state.capacity);

    const title = 'Generation & Forecast';
    const options = {
      ...stocksChartOptions({
        filename: title,
        legend: {
          itemMarginTop: 16,
          labelFormatter: function () {
            return !Number.isNaN(wapes[this.name]) && this.visible
              ? `${this.name}<br>MAPE: ${wapes[this.name].toFixed(2)}%`
              : `${this.name}`;
          },
        },
        events: {
          redraw: function (e) {
            const { min, max } = this.xAxis[0];
            const maxDate =
              max > that.state.maxDate._i ? that.state.maxDate._i : max;
            const updatedWAPEs = that.calculateWAPEs(min, maxDate, that.state.capacity);

            this.series.forEach((s) => {
              if (s.legendItem?.element) {
                const value =
                  s.visible && !Number.isNaN(updatedWAPEs[s.name])
                    ? `</tspan>MAPE: ${updatedWAPEs[s.name].toFixed(2)}%`
                    : '</tspan>';
                s.legendItem.element.innerHTML =
                  s.legendItem.element.innerHTML.replace(
                    /<\/tspan>(MAPE: \d+\.?\d+%)?/g,
                    value
                  );
              }
            });
          },
        },
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
              order: 0,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
              order: 1,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
              order: 2,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
              order: 3,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
              order: 4,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'all',
              text: 'All',
              title: 'View all',
              order: ALL_DATA_ORDER,
              events: {
                click: handleTimePeriodAllClick,
              },
            },
          ],
          selected: this.state.selectedPeriod,
        },
      }),
      yAxis: [
        {
          title: {
            text: 'MW',
          },
        },
      ],
      xAxis: {
        ordinal: false,
        title: {
          text: 'Production End Time, UTC',
        },
        type: 'datetime',
      },
      series: [
        makeSeries('Production, MW', this.props.power),
        makeSeries('Forecast 11AM, MW', this.props.forecast, {
          legendIndex: 1,
        }),
        makeSeries('Forecast latest, MW', this.props.latest_forecast, {
          legendIndex: 2,
        }),
        // makeSeries(this.state.capacity[0].label, this.state.capacity[0].data, {legendIndex: 3, yAxis: 1})
        ...renderCapacitySeries,
      ],
      tooltip: {
        shared: true,
        // Note: Use regular function instead of arrow one
        // to have TooltipFormatterContext as this
        formatter: function () {
          const reducer = (accumulator, currentValue) =>
            accumulator +
            '<br/><br/><span style="color:' +
            currentValue.color +
            '">\u25CF</span>' +
            currentValue.series.name +
            ': <strong>: ' +
            currentValue.y.toFixed(3) +
            '</strong>';

          // Data from BE have come in UTC
          const s = moment.tz(this.x, 'UTC').format('dddd, MMM DD, YYYY HH:SS');
          const points = this.points ?? [];
          const sortedPoints = points.sort((a, b) =>
            a.series.index < b.series.index
              ? -1
              : a.series.index > b.series.index
              ? 1
              : 0
          );

          return s + sortedPoints.reduce(reducer, '');
        },
      },
    };

    let activePark = this.state.parks.filter(
      (item) => item.id === this.state.park?.id
    );
    activePark = activePark.length ? activePark[0] : null;

    if (this.props.isLoggedIn && this.state.park) {
      const newtableData = tableData.map((x) => [
        x[0].toString(),
        x[1],
        x[2],
        x[3],
      ]);

      return (
        <>
          <Grid container spacing={4}>
            <ParkSelector 
              country_code={this.state.park.location?.country_iso3166}
              country_name={this.state.park.location?.country_name}
              options={this.state.parks}
              selected={activePark}
              handleChange={this.on_park_select}
              capacity={this.state.park.capacity.toFixed(1)}
              nonActiveParks={this.state.showNonActive}
              handleChangeNonActiveParks={this.handleChangeNonActiveParks}
            />
            <Grid item xs={12} sm={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{title}</h4>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <div className={classes.container}>
                        {(this.props.loading || this.props.loadingChunks) && (
                          <div
                            className={
                              this.props.forecast?.length
                                ? classNames(
                                    classes.loadingContainer,
                                    classes.sizeS
                                  )
                                : classes.loadingContainer
                            }
                          >
                            <div
                              className={
                                this.props.forecast?.length
                                  ? classNames('loader', 'sizeS')
                                  : 'loader'
                              }
                              alt="Loading report..."
                            />
                          </div>
                        )}
                        {this.props.forecast?.length > 0 && (
                          <HighchartsReact
                            ref={this.chartRef}
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={options}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardBody>
                <CardFooter>
                  <Button color="primary" onClick={this.getData}>
                    Refresh
                  </Button>
                </CardFooter>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Productive units</h4>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Table
                        tableHeaderColor="primary"
                        tableHead={tableHead}
                        tableData={newtableData}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
            {convertData.length > 0 ? (
              <Grid item xs={12} sm={12}>
                <MaterialTable
                  title={"Park availabilities (UTC)"}
                  columns={that.avColumns()}
                  data={avData}
                  options={{
                    headerStyle: headerStyle,
                    pageSize: 10,
                    draggable: false,
                    search: false,
                    maxBodyHeight: window.innerHeight - 150,
                    paging: false,
                    exportMenu: [
                      {
                        label: 'Export CSV',
                        exportFunc: this.exportFunc,
                      },
                    ],
                  }}
                  components={{
                    Row: ({ data }) => this.renderTableRow(data),
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </>
      );
    } else {
      return <LoginPage />;
    }
  }
}

const ForecastConnect = connect(mapStateToProps, mapDispatchToProps)(Forecast);
export default withStyles(styles)(ForecastConnect);
