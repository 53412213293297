const initPart = {
    'label': null,
    'parks': [],
    'activePark': null,
    'curPage': 0,
    'curRegion': 'ALL',
    'mainMarket': null,
    'reporting': false,    
    'showInstanceReport': false,
    'showAuditDashboard': false,
}
export const APIS = {
    'Ane': {
        'API_URL': 'https://ane.sama.energy',
        target: 'ane',
        ...initPart,
        // reporting: true,
    },
    'AXPO CH': {
        'API_URL': 'https://axpo-ch.sama.energy',
        target: 'axpo-ch',
        ...initPart,
        reporting: true,
    },
    'AXPO DE': {
        'API_URL': 'https://axpo-de.sama.energy',
        target: 'axpo-de',
        ...initPart,
        reporting: true,
        showInstanceReport: true,
        showAuditDashboard: true,
    },
    'AXPO PL': {
        'API_URL': 'https://axpo-pl.sama.energy',
        target: 'axpo-pl',
        ...initPart,
        reporting: true,
    },
    'Backtest': {
        'API_URL': 'https://bt-customer-db.sama.energy',
        target: 'backtest',
        ...initPart,
    },
    'BEE': {
        'API_URL': 'https://bee.sama.energy',
        target: 'bee',
        ...initPart,
        reporting: true,
        shutdownSignals: true,
    },
    'Dev': {
        'API_URL': 'https://dev-005.sama.energy:8443',
        'target': 'default@dev005',
        ...initPart,
    },
    'E2m': {
        'API_URL': 'https://e2m.sama.energy',
        target: 'e2m',
        ...initPart,
        reporting: true,
    },
    'Electroroute': {
        'API_URL': 'https://electroroute.sama.energy',
        target: 'electroroute',
        ...initPart,
        reporting: true,
    },
    'Eneco': {
        'API_URL': 'https://eneco.sama.energy',
        target: 'eneco',
        ...initPart,
        reporting: true,
    },
    'Energymc': {
        'API_URL': 'https://energymc.sama.energy',
        target: 'energymc',
        ...initPart,
        reporting: true,
    },
    'EP' : {
        'API_URL': 'https://ep.sama.energy',
        target: 'ep',
        ...initPart,
        reporting: true,        
    },
    'EWZ': {
        'API_URL': 'https://ewz.sama.energy',
        target: 'ewz',
        stopTradingChannel: 'ewzfr-scheduler-state',
        ...initPart,
        reporting: true,
        shutdownSignals: true,
    },
    'market': {
        'API_URL': 'https://datafeeds-02.sama.energy',
        target: 'market',
        ...initPart,
    },
    'Pureenergy': {
        'API_URL': 'https://pureenergy.sama.energy',
        target: 'pureenergy',
        ...initPart,
        reporting: true,
    },
    'Qsol': {
        'API_URL': 'https://qsol.sama.energy',
        target: 'qsol',
        ...initPart,
        reporting: true,
        showAddedMonatsmarktwerte: true,
        shutdownSignals: true,
    },
    'Staging': {
        'API_URL': 'https://stage.sama.energy:8043',
        'target': 'staging',
        ...initPart,
    },
    'Statarbitrage': {
        'API_URL': 'https://statarbitrage-002.sama.energy',
        target: 'statarbitrage',
        ...initPart,
        mainMarket: 'Germany',
    },
    'Statarbitrage-FR': {
        'API_URL': 'https://statarbitrage-001.sama.energy',
        target: 'fr_statarbitrage',
        ...initPart,
        mainMarket: 'France',
    },
    'Sunnic': {
        'API_URL': 'https://sunnic.sama.energy',
        target: 'sunnic',
        ...initPart,
        reporting: true,
    },
    'Test': {
        'API_URL': 'https://staging-test.sama.energy',
        'target': 'staging',
        ...initPart,
    },
    'Test2': {
        'API_URL': 'https://strategy1.sama.energy',
        'target': 'strategy1',
        ...initPart,
    },
    'Trial': {
        'API_URL': 'https://trial.sama.energy',
        target: 'trial',
        ...initPart,
        reporting: true,
    },
    'Uniper': {
        'API_URL': 'https://uniper.sama.energy',
        target: 'uniper',
        ...initPart,
        reporting: true,
    },
    'WindEnergyTrading': {
        'API_URL': 'https://windenergytrading.sama.energy',
        target: 'windenergytrading',
        'TG': {
            'url': 'https://windenergytrading.sama.energy:8143',
            'user': 'monitor',
            'pass': 'ZFtQkBhzmUCaRIIXHvwiQYMo'
        },
        ...initPart,
        reporting: true,
    },
}

export const API_DEFAULT = 'AXPO DE';

export const DEFAULT_PAGE_SIZE = 20;

// Date Formats
export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_DASH = 'YYYY-MM-DD';
export const DATE_FORMAT_DOTS = 'DD.MM.YYYY';

// Units
export const EUR = '\u20AC';
export const EUR_MWH = '\u20AC/MWh';

export const ACCOUNTS_API_PORT = process.env['REACT_APP_ACCOUNTS_API_PORT'];
export const OAMONITOR_IMAGE_TAG = process.env['REACT_APP_OAMONITOR_IMAGE_TAG'];

// DAvsID, DAvsIB, IDvsID, IDvsIB, DA+IDvsIB, DAvsID+IB, DA+IDvsID+IB
export const STRATEGIES = ['DAvsIB', 'DAvsID', 'IDvsID', 'IDvsIB', 'DAvsDA', 'DAIDvsIB', 'DAvsIDIB'];

export const CONTRACTS = ['1h','30min','15min'];
