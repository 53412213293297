import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import ProfitLossPeriod from 'components/Reports/ProfitLossPeriod';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
import DownloadButton from 'components/CustomButtons/DownloadButton';
import DownloadDetailedReport from 'components/CustomButtons/DownloadDetailedReport';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable from '@material-table/core';
import LoginPage from 'views/Login/Oops.jsx';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import checkboxAdnRadioStyle from 'assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  buildAPIRequest,
  security_fetch_params,
  getTradingGW,
  buildGWAPIRequest,
  withGWLogin,
  getDefaultPark,
  getParks,
  get_markets_data,
  get_generation_data,
  updatePnlCurrentSettings,
  updateBatteryTradingSettings,
} from 'actions/index';
import { stocksChartOptions, makeSeries, xAxisInUTC } from 'variables/charts';
import moment from 'moment';
import { logout } from 'utils/auth';
import { DATE_FORMAT, DATE_FORMAT_DASH } from 'constants/general';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import {
  oneDimVol,
  oneDimDaPriceSam,
  resampleArrToHoursDim,
  calcGranData,
} from 'utils/calcFunctions';
import { 
  getParkData,
  getActualCapacity,
  getMarketData
} from 'utils/getDataMethods';
import * as helper from '../KPI/PNLAnalysisHelper';
import { downloadParks } from 'actions';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    inactiveParks: state.reports.parks,
    tableData: state.reports.plreport.tableData,
    tableHead: state.reports.plreport.tableHead,
    loading: state.reports.plreport.loading,
    updatedParks: state.reports.parks,
    markets: state.markets.markets,
    report_productive: state.generation.report_productive,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchParks: (data) => dispatch(getParks(data, 'pnlPeriod')),
  getMarkets: () => dispatch(get_markets_data()),
  get_generation_data: (data) => dispatch(get_generation_data(data)),
  update_pnl_current_settings: (data) =>
    dispatch(updatePnlCurrentSettings(data)),
  update_battery_trading_settings: (data) =>
    dispatch(updateBatteryTradingSettings(data)),
});

const styles = {
  ...checkboxAdnRadioStyle,
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  spacing: {
    marginRight: '1rem',
  },
  bgColor: {
    backgroundColor: '#fff',
  },
  bgColorAlt: {
    backgroundColor: '#eee',
  },
  textColor: {
    color: '#000',
  },
  width300: {
    width: '300px',
  },
  sticky: {
    position: 'sticky',
    top: '-10px',
    zIndex: '99',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
};

class ProfitLossInterval extends Component {
  constructor(props) {
    super(props);
    var end_date = moment().clone().add(-1, 'day');
    var start_date = end_date.clone().startOf('month');
    const parks = this.props.battery
      ? this.props.conn.parks.filter((el) => el.name.includes('Battery'))
      : this.props.conn.parks.filter((el) => !el.name.includes('Battery'));
    const park = (this.props.battery ? parks[0] : getDefaultPark()) || {
      id: -1,
    };
    this.chartRef = React.createRef();

    const portfolio = this.props.battery
      ? this.props.conn.batteryTradingSettings
        ? this.props.conn.batteryTradingSettings.portfolio
        : park
      : this.props.conn.pnlCurrentSettings
      ? this.props.conn.pnlCurrentSettings.portfolio
      : park;

    const startDate = this.props.battery
      ? this.props.conn.batteryTradingSettings
        ? this.props.conn.batteryTradingSettings.start_date
        : start_date.format('YYYY-MM-DD')
      : this.props.conn.pnlCurrentSettings
      ? this.props.conn.pnlCurrentSettings.start_date
      : start_date.format('YYYY-MM-DD');

    const endDate = this.props.battery
      ? this.props.conn.batteryTradingSettings
        ? this.props.conn.batteryTradingSettings.end_date
        : end_date.format('YYYY-MM-DD')
      : this.props.conn.pnlCurrentSettings
      ? this.props.conn.pnlCurrentSettings.end_date
      : end_date.format('YYYY-MM-DD');

    const nonActiveParks = this.props.battery
      ? this.props.conn.batteryTradingSettings
        ? this.props.conn.batteryTradingSettings.nonActiveParks
        : false
      : this.props.conn.pnlCurrentSettings
      ? this.props.conn.pnlCurrentSettings.nonActiveParks
      : false;

    this.state = {
      loading: false,
      tableData: [],
      pl_data_optimised: [],
      pl_data_base: [],
      pl_hourly: [],
      pl_hourly_base: [],
      portfolio: portfolio,
      start_date: startDate,
      end_date: endDate,
      forceReCalculate: false,
      customerPnlData: null,
      nonActiveParks: nonActiveParks,
      daPricesObj: {},
      totalSamawattNoId: {},
      cashFlow: false,
      prodSales: [],
      daOpenPositionWithError: [],
      forecastingError: [],
      currency: '',
      isOpen: true,
      scrollValue: 0,
      tableYearDataWithTotal: [],
      hasAltPnl: false,
      hasCustCustPnl: false,
      tradingGateway: getTradingGW(),
      tableHeader: [],
      tableHeaderYear: [],
      tableDataRender: [],
      tableYearDataWithTotalRender: [],
      showEachAuction: false,
      options: parks,
      reportData: null,
      kpiRows: null,
      davsibParksId: [],
    };
    this.start_build = this.start_build.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.injectCustomerPnl = this.injectCustomerPnl.bind(this);
    this.handleChangeNonActiveParks =
      this.handleChangeNonActiveParks.bind(this);
    this.getMarktIdDeltaTz = this.getMarktIdDeltaTz.bind(this);

    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart, 'beforeShowResetZoom');
  }

  handleChange(event, value) {
    // Options could have an 'id' like 'portfolio-option-1'
    const name = event.target.id.includes('portfolio')
      ? 'portfolio'
      : event.target.id;
    this.setState({ [name]: value ?? event.target.value });
  }

  handleChangeCheckbox(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleChangeNonActiveParks(event) {
    if (event.target.checked) {
      this.props.fetchParks({ isAllParks: event.target.checked });
      const inactiveParks = this.props.battery
        ? this.props.inactiveParks.filter((el) => el.name.includes('Battery'))
        : this.props.inactiveParks.filter((el) => !el.name.includes('Battery'));
      this.setState({
        nonActiveParks: event.target.checked,
        options: inactiveParks,
      });
    } else {
      const parks = this.props.battery
        ? this.props.conn.parks.filter((el) => el.name.includes('Battery'))
        : this.props.conn.parks.filter((el) => !el.name.includes('Battery'));
      this.setState({ nonActiveParks: event.target.checked, options: parks });
    }
  }

  componentDidMount() {
    this.props.fetchParks({ isAllParks: false });

    const onLoad = (data) => {
      const davsibParksId = data.data.map(el => el.id);
      this.setState({davsibParksId: davsibParksId});
    }
    downloadParks({strategies: ['DAvsIB']}, onLoad)

    if (this.props.conn.pnlCurrentSettings?.nonActiveParks)
      this.props.fetchParks({
        isAllParks: this.props.conn.pnlCurrentSettings.nonActiveParks,
      });
    this.props.getMarkets();
    alertify.notify('Start getting markets list', 5);
    
    this.props.get_generation_data({
      windpark: this.state.portfolio.id,
      dateFrom: moment(this.state.start_date).format(DATE_FORMAT),
      dateTo: moment(this.state.end_date).format(DATE_FORMAT),
      isDownloadingByChunks: false,
    })

    if (
      (this.props.markets.length &&
        this.props.conn.pnlCurrentSettings &&
        !this.props.battery) ||
      (this.props.markets.length &&
        this.props.conn.batteryTradingSettings &&
        this.props.battery)
    ) {
      this.start_build();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.nonActiveParks &&
      JSON.stringify(prevProps.inactiveParks) !==
        JSON.stringify(this.props.inactiveParks)
    ) {
      const inactiveParks = this.props.battery
        ? this.props.inactiveParks.filter((el) => el.name.includes('Battery'))
        : this.props.inactiveParks.filter((el) => !el.name.includes('Battery'));
      this.setState({ options: inactiveParks });
    }

    // when list of parks is loaded
    if (this.props.conn.parks.length !== prevProps.conn.parks.length) {
      const parks = this.props.battery
        ? this.props.conn.parks.filter((el) => el.name.includes('Battery'))
        : this.props.conn.parks.filter((el) => !el.name.includes('Battery'));
      const park = (this.props.battery ? parks[0] : getDefaultPark()) || {
        id: -1,
      };
      const portfolio = this.props.battery
        ? this.props.conn.batteryTradingSettings
          ? this.props.conn.batteryTradingSettings.portfolio
          : park
        : this.props.conn.pnlCurrentSettings
        ? this.props.conn.pnlCurrentSettings.portfolio
        : park;
      this.setState({
        options: parks,
        portfolio: portfolio,
      });
    }

    if (
        this.state.portfolio.id !== prevState.portfolio.id
    ) {
      this.props.get_generation_data({
        windpark: this.state.portfolio.id,
        dateFrom: moment(this.state.start_date).format(DATE_FORMAT),
        dateTo: moment(this.state.end_date).format(DATE_FORMAT),
        isDownloadingByChunks: false,
      })
    }
    
    if (this.props.markets !== prevProps.markets) {
      alertify.success('Markets list is loaded', 5);
    }

    if (
      this.chartRef &&
      JSON.stringify(this.state.tableDataRender) !==
        JSON.stringify(prevState.tableDataRender)
    ) {
      document.getElementById('mainPanel').scroll(0, this.state.scrollValue);
    }

    if (
      this.state.tableData !== prevState.tableData ||
      this.state.cashFlow !== prevState.cashFlow ||
      this.state.totalSamawattNoId !== prevState.totalSamawattNoId ||
      this.state.prodSales !== prevState.prodSales
    ) {
      let tableData;
      let tableHeader;
      let tableHeaderYear;
      let tableYearDataWithTotal = [];
      if (this.tableHeader?.length && this.state.tableData.length) {
        tableHeaderYear = [...this.tableHeaderYear];
        tableHeader = [...this.tableHeader];
        tableData = this.state.tableData.map(function (arr) {
          return arr.slice();
        });
      }

      //Prepare Net P&L data for the table
      //First and end dates in tabledata and productionSalesDays should be the same
      if (
        this.state.tableData.length &&
        this.state.cashFlow &&
        this.state.prodSales.length &&
        this.state.daOpenPositionWithError.length &&
        this.state.forecastingError.length &&
        Object.keys(this.state.totalSamawattNoId).length
      ) {
        let sumProdSalesDays = 0;
        let sumDaOpenPosition = 0;
        let sumForecastingError = 0;
        let sumIntradayPnl = 0;
        tableData.forEach((el, id) => {
          if (el[0] !== 'Total') {
            const prodSalesDay = Number(this.state.prodSales[id].toFixed(2));
            const daOpenPositionWithError = Number(
              this.state.daOpenPositionWithError[id].toFixed(2)
            );
            const forecastingError = Number(
              this.state.forecastingError[id].toFixed(2)
            );
            const intradayPnl = el[4] - this.state.totalSamawattNoId[el[0]];
            tableData[id][1] = prodSalesDay;
            tableData[id][2] = daOpenPositionWithError;
            tableData[id][3] = intradayPnl;
            tableData[id].splice(3, 0, forecastingError);
            sumProdSalesDays += Number(prodSalesDay);
            sumDaOpenPosition += Number(daOpenPositionWithError);
            sumForecastingError += Number(forecastingError);
            sumIntradayPnl += Number(intradayPnl);
          } else {
            tableData[id][1] = sumProdSalesDays;
            tableData[id][2] = sumDaOpenPosition;
            tableData[id][3] = sumIntradayPnl;
            tableData[id].splice(3, 0, sumForecastingError);
          }
        });

        tableHeader.forEach((el, id) => {
          tableHeader[1] = `Production Sales, ${this.state.currency || ''}`;
          tableHeader[2] = `DA open position, ${this.state.currency || ''}`;
          tableHeader[3] = `Intraday pnl, ${this.state.currency || ''}`;
          tableHeaderYear[1] = `Production Sales, ${this.state.currency || ''}`;
          tableHeaderYear[2] = `DA open position, ${this.state.currency || ''}`;
          tableHeaderYear[3] = `Intraday pnl, ${this.state.currency || ''}`;
        });

        tableHeader.splice(
          3,
          0,
          `Forecasting error, ${this.state.currency || ''}`
        );
        tableHeaderYear.splice(
          3,
          0,
          `Forecasting error, ${this.state.currency || ''}`
        );
      }
      if (tableData) tableYearDataWithTotal = this.calcTableYearData(tableData);
      this.setState({
        tableDataRender: tableData,
        tableHeader,
        tableHeaderYear,
        tableYearDataWithTotalRender: tableYearDataWithTotal,
      });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date
    ) {
      return false;
    } else {
      return true;
    }
  }

  build_series_processor(field, dt, fillWithZero = false) {
    return (accumulator, currentValue) => {
      const extra_data = JSON.parse(currentValue.extra_data);
      let period = extra_data.parameters[dt];
      let values;
      if (field === 'da_volumes_samawatt' || field === 'id_volumes_samawatt') {
        values = oneDimVol(
          JSON.parse(currentValue[field]), 
          period, 
          extra_data.ib_prices[0].length * extra_data.parameters[2]
        );
      } else if (field === 'da_volumes_customer') {
        values = resampleArrToHoursDim(JSON.parse(currentValue[field]), period);
      } else {
        values = JSON.parse(currentValue[field]);
      }
      const { date } = currentValue;
      // Because previously resampled using oneDimVol function
      if (
        field === 'da_volumes_samawatt' ||
        field === 'id_volumes_samawatt' ||
        field === 'da_volumes_customer'
      ) {
        period = 1;
      }
      return accumulator.concat(
        values.map((x, index) => [
          moment(date)
            .add(index * period, 'hours')
            .unix() * 1000,
          // Divide to period for converting MWh to MW (energy to power).
          // To have equal values for DA, ID and IB charts (OP-975).
          !fillWithZero ? Math.round((x / period) * 1000.0) / 1000.0 : 0.0,
        ])
      );
    };
  }

  build_series_processor_stage(field, dt, stage) {
    return (accumulator, currentValue) => {
      let period = JSON.parse(currentValue.extra_data).parameters[dt][stage];
      let values;
      values = JSON.parse(currentValue[field])[stage];
      const { date } = currentValue;
      return accumulator.concat(
        values.map((x, index) => [
          moment(date)
            .add(index * period, 'hours')
            .unix() * 1000,
          // Divide to period for converting MWh to MW (energy to power).
          // To have equal values for DA, ID and IB charts (OP-975).
          Math.round((x / period) * 1000.0) / 1000.0,
        ])
      );
    };
  }

  injectCustomerPnl(tableData) {
    const data = Array.isArray(this.state.customerPnlData)
      ? this.state.customerPnlData
      : [this.state.customerPnlData];
    const data_dict = {};

    for (let i = 0; i < data.length; ++i) {
      data_dict[data[i].date] = data[i];
    }

    // Add customer pnl column to tableData
    const customerPnlIndex = tableData[0].length - 1;
    for (let i = 0; i < tableData.length - 1; i++) {
      const data_item = data_dict[tableData[i][0]];

      if (data_item) {
        tableData[i].splice(customerPnlIndex, 0, data_item.daily_pnl);
      } else {
        tableData[i].splice(customerPnlIndex, 0, '');
      }
    }
    const total_customer_pnl = data.reduce(
      (accumulator, currentValue) => accumulator + currentValue.daily_pnl,
      0
    );
    // Add total customer pnl to tableData
    tableData[tableData.length - 1].splice(
      customerPnlIndex,
      0,
      total_customer_pnl
    );
    this.setState({ tableData });
  }

  getMarktIdDeltaTz() {
    const market = this.props.markets.filter(
      (el) => el.name === this.state.portfolio.market
    );
    const marketId = market[0]?.id;
    // delta in milliseconds between market tz and utc
    let deltaUtc = 0;
    if (market.length)
      deltaUtc = moment().tz(market[0]?.tz_long).utcOffset() * 60 * 1000;
    return { marketId, deltaUtc };
  }

  async start_build(date1, date2) {
    if (this.props.battery) {
      this.props.update_battery_trading_settings({
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        portfolio: this.state.portfolio,
        nonActiveParks: this.state.nonActiveParks,
      });
    } else {
      this.props.update_pnl_current_settings({
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        portfolio: this.state.portfolio,
        nonActiveParks: this.state.nonActiveParks,
      });
    }
    this.setState({ loading: true, tableData: [] });
    this.setState({
      scrollValue: document.getElementById('mainPanel').scrollTop,
    });
    var that = this;
    var forceReCalculate = this.state.forceReCalculate;
    let market = this.props.markets.filter(
      (el) => el.name === this.state.portfolio.market
    );
    const currency = market ? market[0].currency : 'EUR';
    const parkData = await getParkData(
      this.state.portfolio.id,
      this.props.conn.label
    );

    const tradingGateway = getTradingGW();
    this.tableHeader = [
      'Date',
      `Day-ahead Optimised, ${currency || ''}`,
      `Intraday Optimised, ${currency || ''}`,
      `Imbalance Optimised, ${currency || ''}`,
      `Total Optimised, ${currency || ''}`,
      `Day-ahead base, ${currency || ''}`,
      `Imbalance base, ${currency || ''}`,
      `Total base, ${currency || ''}`,
      `${this.props.conn.showAddedMonatsmarktwerte ? 'Added Value EEG' : 'Added value'}, ${currency || ''}`,
      ...(this.props.conn.showAddedMonatsmarktwerte ? [`Added Value Monatsmarktwerte, ${currency || ''}`] : []),
      ...(tradingGateway ? [`Customer PnL, ${currency || ''}`] : []),
      'Actions',
    ];
    this.tableHeaderYear = [...this.tableHeader];
    this.tableHeaderYear[this.tableHeaderYear.length - 1] = '';

    let capacityObj = {}
    
    if (this.props.conn.shutdownSignals) {
      const capacityData = await getActualCapacity(
        this.props.report_productive, 
        this.state.portfolio.id, 
        moment.utc(this.state.start_date).add(-1, 'day'), 
        moment.utc(this.state.end_date).add(1, 'day'), 
        this.props.conn.label
      );
  
      capacityData[0].data.forEach(el => {
        const date = moment.utc(el[0]).tz('Europe/Berlin').format(DATE_FORMAT_DASH);
        capacityObj = {
          ...capacityObj,
          [date]: [...(capacityObj[date] ? capacityObj[date] : []), el]
        }
      })
    }

    let chartData;
    let altChartData;
    if (this.props.conn.showAddedMonatsmarktwerte) {
      const data = await getMarketData(19, 'netztransparenz/MW Solar', moment(this.state.start_date).startOf('month'), moment(this.state.end_date), this.props.conn.label, null, 86400);
      const altData = await getMarketData(19, 'Proxy-Monthly-Index/Solar Proxy Monthly Index', moment(this.state.start_date).startOf('month'), moment(this.state.end_date), this.props.conn.label, null, 86400);
      if (data.data) {
        chartData = data.data.length ? data.data[0].map(el => [moment(el[0]).format('YYYY-MM'), el[1]]) : [];
      }
      if (altData.data) {
        altChartData = altData.data.length ? altData.data[0].map(el => [moment(el[0]).format('YYYY-MM'), el[1]]) : [];
      }
    }
    // Get PnL Data with ID
    let [url, headers] = buildAPIRequest(
      '/api/windparks/' +
        this.state.portfolio.id +
        '/pl_interval/' +
        this.state.start_date.replaceAll('-', '') +
        '/' +
        this.state.end_date.replaceAll('-', '') +
        '?noID=0&details=1&forceReCalculate=' +
        (forceReCalculate ? '1' : '0')
    );

    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error('Response error');
              console.log(data.error);
              return;
            }
            for (let i = 0; i < data.errors.length; i++) {
              var tm = moment(data.errors[i]).format('YYYY-MM-DD');
              alertify.error('Data for ' + tm + ' is missed', 'error', 10);
            }

            // Check for Alternative PNL data
            const hasAltPnl = data.data
              .map((x) => x.alt_total_samawatt)
              .some((x) => x !== null);
            if (hasAltPnl) {
              this.tableHeader.splice(
                this.tableHeader.length - 1,
                0,
                'Alternative PnL'
              );
              this.tableHeaderYear.splice(
                this.tableHeaderYear.length - 1,
                0,
                'Alternative PnL'
              );
            }

            // Check for customer provided customer PNL data
            const hasCustCustPnl = data.data
              .map((x) => x.pl_customer_customer)
              .some((x) => x !== null);
            if (hasCustCustPnl) {
              if (tradingGateway) {
                console.warn('Two mutually exclusive customer PnL transports.');
              }
              this.tableHeader.splice(
                this.tableHeader.length - 1,
                0,
                'Customer PnL'
              );
              this.tableHeaderYear.splice(
                this.tableHeaderYear.length - 1,
                0,
                'Customer PnL'
              );
            }

            let totalAltPNL = 0;
            let totalCustCustPNL = 0;
            var tableData = data.data.map((x) => {
              const altPNL = x.alt_total_samawatt ?? '';
              totalAltPNL += x.alt_total_samawatt ? x.alt_total_samawatt : 0;
              const custcustPNL = x.pl_customer_customer ?? '';
              totalCustCustPNL += x.pl_customer_customer
                ? x.pl_customer_customer
                : 0;
              const actualProduction = x.generation.reduce((a, v) => a + v);
              const totalOptimised = x.total_samawatt;
              const monatsmarktwerte = chartData && chartData.find(el => el[0] === moment(x.date).format('YYYY-MM')) 
                ? chartData.find(el => el[0] === moment(x.date).format('YYYY-MM'))[1] 
                : altChartData && altChartData.find(el => el[0] === moment(x.date).format('YYYY-MM')) 
                  ? altChartData.find(el => el[0] === moment(x.date).format('YYYY-MM'))[1] 
                  : 'N/A';
              const newAddedValue = chartData && altChartData && monatsmarktwerte !== 'N/A' ? totalOptimised - actualProduction * monatsmarktwerte : 'N/A';
              return [
                x.date,
                x.da_samawatt,
                x.id_samawatt,
                x.ib_samawatt,
                x.total_samawatt,
                x.da_customer,
                x.ib_customer,
                x.total_customer,
                x.added_value,
                // Inject Alternative PNL data
                ...(this.props.conn.showAddedMonatsmarktwerte ? [newAddedValue] : []),
                ...(hasAltPnl ? [altPNL] : []),
                ...(hasCustCustPnl ? [custcustPNL] : []),
                '',
              ];
            });

            // Calculate Production Sales using extra_data
            const prodSales = data.data.map((el) => {
              const extra_data = JSON.parse(el.extra_data);
              const daDt = extra_data.parameters[0];
              const daIb = extra_data.parameters[2];
              const generations = resampleArrToHoursDim(
                el.generation,
                daIb
              );
              const daPrices = JSON.parse(el.extra_data).da_prices;
              const da_samawatt_detail = JSON.parse(el.da_samawatt_detail);
              const daTradesAuctions = da_samawatt_detail.map((trades, i) => {
                const daTradesAuction = trades.map((trade, j) => {
                  return daPrices[i][j] !== 0 ? trade / daPrices[i][j] : 0;
                });
                return daTradesAuction;
              });
              const daPricesAvr = oneDimDaPriceSam(
                daTradesAuctions,
                JSON.parse(el.extra_data).da_prices,
                daDt
              );
              const prodCashFlow = daPricesAvr.map((el, id) => {
                return el * generations[id];
              });
              const prodSal = prodCashFlow.reduce((a, b) => a + b, 0);
              return prodSal;
            });
            let daOpenPositionWithError = [];
            let forecastingError = [];

            // Calculate DA Open Position && Forecasting Error
            data.data.forEach((el) => {
              const extra_data = JSON.parse(el.extra_data);
              const daIb = extra_data.parameters[2];
              const generations = el.generation;
              const baseVolumes = el.base_volumes;
              const da_prices = extra_data.da_prices.length ? extra_data.da_prices[0] : [];
              const da_volumes_samawatt = JSON.parse(el.da_volumes_samawatt);
              const da_volumes = da_volumes_samawatt.length ? da_volumes_samawatt[0] : []

              const daPrices = calcGranData(
                da_prices,
                extra_data.parameters[1][0],
                daIb,
                'price'
              );
              const daVolumes = calcGranData(
                da_volumes,
                extra_data.parameters[1][0],
                daIb,
                'vol'
              );
              const ibPriceUp = extra_data.ib_prices[1];
              const ibPriceDown = extra_data.ib_prices[0];
              
              const daOpenPosSum = daVolumes
                .map((daTrade, i) => {
                  const capacity = capacityObj[el.date] ? capacityObj[el.date][Math.floor(i * daIb)] : null;
                  return daTrade - generations[i] > 0
                    ? (daTrade - (capacity && capacity[1] === 0 ? 0 : baseVolumes[i])) *
                        (daPrices[i] - ibPriceDown[i])
                    : (daTrade - (capacity && capacity[1] === 0 ? 0 : baseVolumes[i])) * (daPrices[i] - ibPriceUp[i]);
                })
                .reduce((a, v) => a + v, 0);

              const forecastingErrSum = daVolumes
                .map((daTrade, i) => {
                  const capacity = capacityObj[el.date] ? capacityObj[el.date][Math.floor(i * daIb)] : null;
                  return daTrade - generations[i] > 0
                    ? ((capacity && capacity[1] === 0 ? 0 : baseVolumes[i]) - generations[i]) *
                        (daPrices[i] - ibPriceDown[i])
                    : ((capacity && capacity[1] === 0 ? 0 : baseVolumes[i]) - generations[i]) *
                        (daPrices[i] - ibPriceUp[i]);
                })
                .reduce((a, v) => a + v, 0);

              daOpenPositionWithError.push(daOpenPosSum);
              forecastingError.push(forecastingErrSum);
            });

            var pl_data_optimised = data.data.map(x => {
              return [
                moment.utc(x.date).unix() * 1000,
                x.total_samawatt]
            });

            // Build a cumulative pnl values
            var cum_profit = 0;
            var pl_data_optimised_cumulative = [];
            for (let i = 0; i < data.data.length; i++) {
              cum_profit += data.data[i].added_value;
              pl_data_optimised_cumulative.push([
                moment.utc(data.data[i].date).unix() * 1000,
                cum_profit
              ]);
            }

            var pl_data_base = data.data.map(x => {
              return [
                moment.utc(x.date).unix() * 1000,
                x.total_customer]
            });

            var da_volumes_optimised = data.data.reduce(
              that.build_series_processor('da_volumes_samawatt', 0),
              []
            );
            const da_volumes_optimised_arr = [];
            const daDt = data.data[0]
              ? JSON.parse(data.data[0].extra_data).parameters[0]
              : [];
            if (daDt.length > 1) {
              for (let i = 0; i < daDt.length; i++) {
                da_volumes_optimised_arr.push(
                  data.data.reduce(
                    that.build_series_processor_stage(
                      'da_volumes_samawatt',
                      0,
                      i
                    ),
                    []
                  )
                );
              }
            }

            // 2 because granularity for da_volumes_base same as for ib
            var da_volumes_base = data.data.reduce(
              that.build_series_processor('da_volumes_customer', 2),
              []
            );

            //check if DAvsDA and then show separate curves for each auction
            let showEachAuction = false;
            if (
              da_volumes_optimised.every((el) => el[1] === 0) &&
              da_volumes_optimised_arr.some(
                (arr) => !arr.every((el) => el[1] === 0)
              )
            ) {
              showEachAuction = true;
            }

            var id_volumes_optimised = data.data.reduce(
              that.build_series_processor('id_volumes_samawatt', 1),
              []
            );

            var id_volumes_base = data.data.reduce(
              that.build_series_processor('id_volumes_samawatt', 1, true),
              []
            );

            var ib_volumes_optimised = data.data.reduce(
              that.build_series_processor('ib_volumes_samawatt', 2),
              []
            );

            var ib_volumes_base = data.data.reduce(
              that.build_series_processor('ib_volumes_customer', 2),
              []
            );

            var total_customer = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.total_customer;
            },
            0);

            var total_optimised = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.total_samawatt;
            },
            0);

            var total_added_value = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.added_value;
            },
            0);

            var total_da_customer = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.da_customer;
            },
            0);

            var total_ib_customer = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.ib_customer;
            },
            0);

            var total_da_samawatt = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.da_samawatt;
            }, 0);

            var total_id_samawatt = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.id_samawatt;
            },
            0);

            var total_ib_samawatt = data.data.reduce(function (
              accumulator,
              currentValue,
              currentIndex,
              array
            ) {
              return accumulator + currentValue.ib_samawatt;
            },
            0);

            const total_new_added_value = chartData && altChartData && !tableData.find(el => el[9] === 'N/A') 
              ? tableData.reduce((a, v) => a + v[9], 0) 
              : 'N/A';
            
            tableData.push([
              'Total',
              total_da_samawatt,
              total_id_samawatt,
              total_ib_samawatt,
              total_optimised,
              total_da_customer,
              total_ib_customer,
              total_customer,
              total_added_value,
              ...(this.props.conn.showAddedMonatsmarktwerte ? [total_new_added_value] : []),
              ...(hasAltPnl ? [totalAltPNL] : []),
              ...(hasCustCustPnl ? [totalCustCustPNL] : []),
              '',
            ]);

            const makeChartDataArr = (data, key) => {
              return data.data
                .map((d) => {
                  const date = moment(d.date).unix();
                  const parsedData = d[key] ? JSON.parse(d[key]) : [];
                  const granularity =
                    parsedData.length === 100 ||
                    parsedData.length === 96 ||
                    parsedData.length === 92
                      ? 0.25
                      : parsedData.length === 50 ||
                        parsedData.length === 48 ||
                        parsedData.length === 46
                      ? 0.5
                      : 1;
                  const granData = calcGranData(
                    parsedData,
                    granularity,
                    1,
                    'vol'
                  );
                  const hourly = granData.map((value, index) => {
                    return [parseInt(date + index * 3600) * 1000, value];
                  });
                  return hourly;
                })
                .flat();
            };

            const pl_hourly = makeChartDataArr(data, 'pl_samawatt');
            const pl_hourly_base = makeChartDataArr(data, 'pl_customer');
            const battery_soc = makeChartDataArr(data, 'soc_detail');

            // Verify if trading gatway service to be used
            if (tradingGateway) {
              withGWLogin().then((token) => {
                if (token) {
                  var [urlGW, headersGW] = buildGWAPIRequest(
                    `/api/customer/pnl_report?` +
                      `date=${this.state.start_date}&end_date=${this.state.end_date}&park_id=${this.state.portfolio.id}`
                  );
                  headersGW['Authorization'] = token;
                  fetch(urlGW, { method: 'GET', headers: headersGW })
                    .then((response) => {
                      if (response.ok) {
                        response.json().then((data) => {
                          if (data.error) {
                            alertify.error('Response error');
                            console.log(data.error);
                            return;
                          }
                          this.setState({ customerPnlData: data }, () =>
                            this.injectCustomerPnl(tableData)
                          );
                        });
                      } else if (response.status === 401) {
                        logout();
                        return;
                      } else {
                        this.setState({ customerPnlData: [] }, () =>
                          this.injectCustomerPnl(tableData)
                        );
                      }
                    })
                    .catch((error) => {
                      alertify.error(error.message, 'error', 5);
                    });
                } else {
                  this.setState({ customerPnlData: [] }, () =>
                    this.injectCustomerPnl(tableData)
                  );
                }
              });
            }

            const { rows } = helper.calculateOptsAndRows([{ ...this.state.portfolio, data: data.data }], [this.state.portfolio], 'Profit/MWh', 'Profit', this.state.davsibParksId)

            that.setState({
              loading: false,
              ...(!tradingGateway && { tableData: tableData }),
              pl_data_base,
              pl_hourly,
              pl_hourly_base,
              pl_data_optimised,
              pl_data_optimised_cumulative,
              da_volumes_optimised,
              da_volumes_optimised_arr,
              da_volumes_base,
              id_volumes_optimised,
              id_volumes_base,
              ib_volumes_optimised,
              ib_volumes_base,
              prodSales,
              daOpenPositionWithError,
              forecastingError,
              hasAltPnl,
              hasCustCustPnl,
              tradingGateway,
              showEachAuction,
              parkData,
              currency,
              battery_soc,
              reportData: data.data,
              kpiRows: rows,
            });
            setTimeout(function () {
              window.dispatchEvent(new Event('resize'));
            }, 500);
            return true;
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        alertify.error(error.message, 'error', 5);
        that.setState({
          loading: false,
          tableData: [],
        });
        return false;
      });

    // Get PnL Data without ID
    const [url_, headers_] = buildAPIRequest(
      '/api/windparks/' +
        this.state.portfolio.id +
        '/pl_interval/' +
        this.state.start_date.replaceAll('-', '') +
        '/' +
        this.state.end_date.replaceAll('-', '') +
        '?noID=1&forceReCalculate=' +
        (forceReCalculate ? '1' : '0')
    );
    fetch(url_, { method: 'GET', headers: headers_, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data.error) {
              alertify.error('Response error');
              console.log(data.error);
              return;
            }
            for (let i = 0; i < data.errors.length; i++) {
              var tm = moment(data.errors[i]).format('YYYY-MM-DD');
              alertify.error('Data for ' + tm + ' is missed', 'error', 10);
            }
            const totalSamawattNoId = {};
            data.data.forEach((el) => {
              totalSamawattNoId[el.date] = el.total_samawatt;
            });

            that.setState({
              totalSamawattNoId,
            });
            setTimeout(function () {
              window.dispatchEvent(new Event('resize'));
            }, 500);
            return true;
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        alertify.error(error.message, 'error', 5);
        return false;
      });
  }

  calcTableYearData = (tableData) => {
    const tableYearDataWithTotal = [];
    const data = JSON.parse(JSON.stringify(tableData));
    const totalRow = data.splice(-1);
    const monthYearObj = {};
    data.forEach((el, id) => {
      const month = moment(el[0]).format('MMM');
      const year = moment(el[0]).format('YYYY');

      if (!monthYearObj[year]) {
        monthYearObj[year] = {};
      }

      if (!monthYearObj[year][month]) {
        monthYearObj[year][month] = {
          da_samawatt: 0,
          id_samawatt: 0,
          ib_samawatt: 0,
          total_samawatt: 0,
          da_customer: 0,
          ib_customer: 0,
          total_customer: 0,
          added_value: 0,
          altPNL: 0,
          custcustPNL: 0,
          newAddedValue: 0,
        };
      }
      monthYearObj[year][month].da_samawatt += el[1];
      monthYearObj[year][month].id_samawatt += el[2];
      monthYearObj[year][month].ib_samawatt += el[3];
      monthYearObj[year][month].total_samawatt += el[4];
      monthYearObj[year][month].da_customer += el[5];
      monthYearObj[year][month].ib_customer += el[6];
      monthYearObj[year][month].total_customer += el[7];
      monthYearObj[year][month].added_value += el[8];
      if (this.state.hasAltPnl && el[9] !== '')
        monthYearObj[year][month].altPNL += el[9];
      if (
        !this.state.hasAltPnl &&
        (this.state.hasCustCustPnl || this.state.tradingGateway) &&
        el[9] !== ''
      ) {
        monthYearObj[year][month].custcustPNL += el[9];
      } else if (
        this.state.hasAltPnl &&
        (this.state.hasCustCustPnl || this.state.tradingGateway) &&
        el[10] !== ''
      ) {
        monthYearObj[year][month].custcustPNL += el[10];
      } else if (this.state.cashFlow) {
        monthYearObj[year][month].altPNL += el[9];
      }
      if (this.props.conn.showAddedMonatsmarktwerte && el[9] === 'N/A') {
        monthYearObj[year][month].newAddedValue = 'N/A';
      } else if (this.props.conn.showAddedMonatsmarktwerte) {
        monthYearObj[year][month].newAddedValue += el[9];
      }
    });

    const tableYearData = [];
    Object.entries(monthYearObj).forEach(([year, months]) => {
      Object.entries(months).forEach(([month, monthData]) => {
        tableYearData.push([
          `${month} ${Number(year)}`,
          // month,
          monthData.da_samawatt,
          monthData.id_samawatt,
          monthData.ib_samawatt,
          monthData.total_samawatt,
          monthData.da_customer,
          monthData.ib_customer,
          monthData.total_customer,
          monthData.added_value,
          ...(this.state.cashFlow ? [monthData.altPNL] : []),
          ...(this.props.conn.showAddedMonatsmarktwerte ? [monthData.newAddedValue] : []),
          ...(this.state.hasAltPnl ? [monthData.altPNL] : []),
          ...(this.state.tradingGateway || this.state.hasCustCustPnl
            ? [monthData.custcustPNL]
            : []),
        ]);
      });
    });
    tableYearData.forEach((el, id) => {
      tableYearDataWithTotal.push(el);
      if (
        (id !== tableYearData.length - 1 &&
          el[0].split(' ')[1] !== tableYearData[id + 1][0].split(' ')[1]) ||
        (id === tableYearData.length - 1 &&
          Object.keys(monthYearObj).length > 1)
      ) {
        const total = tableYearData.filter(
          (x) => x[0].split(' ')[1] === el[0].split(' ')[1]
        );
        const totalData = JSON.parse(JSON.stringify(total)).reduce((a, b) => {
          return a.map((el, i) => {
            if (i > 0) {
              return el + b[i];
            }
            return el;
          });
        });
        totalData[0] = `Total ${totalData[0].split(' ')[1]}`;
        tableYearDataWithTotal.push(totalData);
      }
    });
    // Add value --- instead of month in total row
    // totalRow[0].splice(1, 0, '---');
    // Remove last element in total row
    totalRow[0].splice(-1);
    tableYearDataWithTotal.push(totalRow[0]);
    return tableYearDataWithTotal;
  };

  render() {
    moment.tz.setDefault('Europe/Berlin');
    const { classes } = this.props;
    let activePark = this.state.options.filter(
      (p) => p.id === this.state.portfolio.id
    );
    activePark = activePark.length ? activePark[0] : null;
    const parkName = activePark?.name ?? '';

    const market = this.props.markets.filter(
      (el) => el.name === this.state.portfolio.market
    );
    const marketId = market[0]?.id;

    const optionsCumulative = {
      ...stocksChartOptions({ filename: `Daily P&L Cumulative ${parkName}` }),
      yAxis: [
        {
          title: {
            text: `Profit(Loss), ${this.state.currency || ''}`,
          },
        },
        {
          title: {
            text: `Added Value, ${this.state.currency || ''}`,
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: [
        makeSeries(
          'Added Value',
          this.state.pl_data_optimised_cumulative
            ? this.state.pl_data_optimised_cumulative.map((val) => [
                val[0],
                val[1],
              ])
            : [],
          { valueDecimals: 2 }
        ),
      ],
    };

    const options = {
      ...stocksChartOptions({ filename: `Daily P&L ${parkName}` }),
      yAxis: [
        {
          title: {
            text: `Profit(Loss), ${this.state.currency || ''}`,
          },
        },
        {
          title: {
            text: `Difference, ${this.state.currency || ''}`,
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: [
        makeSeries('Optimised', this.state.pl_data_optimised, {
          valueDecimals: 2,
        }),
        makeSeries('Base', this.state.pl_data_base, { valueDecimals: 2 }),
        makeSeries(
          'Difference',
          this.state.pl_data_optimised === undefined
            ? []
            : this.state.pl_data_optimised.map((val, index) => [
                val[0],
                val[1] - this.state.pl_data_base[index][1],
              ]),
          {
            valueDecimals: 2,
            yAxis: 1,
          }
        ),
      ],
    };

    const hourly = {
      ...stocksChartOptions({ filename: `Daily P&L (hourly) ${parkName}` }),
      yAxis: [
        {
          title: {
            text: `Profit(Loss), ${this.state.currency || ''}`,
          },
        },
        {
          title: {
            text: `Difference, ${this.state.currency || ''}`,
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: [
        makeSeries('Optimised', this.state.pl_hourly, { valueDecimals: 2 }),
        makeSeries('Base', this.state.pl_hourly_base, { valueDecimals: 2 }),
        makeSeries(
          'Difference',
          this.state.pl_hourly === undefined
            ? []
            : this.state.pl_hourly.map((val, index) => [
                val[0],
                val[1] - this.state.pl_hourly_base[index][1],
              ]),
          {
            valueDecimals: 2,
            yAxis: 1,
          }
        ),
      ],
    };

    const daData = [];
    if (
      this.state.parkData?.stages.optimization_job?.every((el) =>
        el[0].includes('AU')
      ) &&
      this.state.parkData?.stages.optimization_job.length >= 2
    ) {
      // check if DAvsDA
      this.state.da_volumes_optimised_arr.forEach((el, id) => {
        let stageName = this.state.parkData?.stages.optimization_job[id][0];
        daData.push(
          makeSeries(`Optimised ${stageName || ''}`, el, {
            valueDecimals: 2,
          })
        );
      });
    } else {
      daData.push(
        makeSeries('Optimised', this.state.da_volumes_optimised, {
          valueDecimals: 2,
        })
      );
    }
    daData.push(
      makeSeries('Base', this.state.da_volumes_base, { valueDecimals: 2 })
    );
    daData.push(
      makeSeries(
        'Difference',
        this.state.da_volumes_optimised
          ? this.state.da_volumes_optimised.map((val, index) => [
              val[0],
              val[1] - this.state.da_volumes_base[index][1],
            ])
          : [],
        {
          valueDecimals: 2,
          yAxis: 1,
        }
      )
    );

    const da_options = {
      ...stocksChartOptions({ filename: `Day-ahead volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'Power, MW',
          },
        },
        {
          title: {
            text: 'Difference, MW',
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: daData,
    };

    const id_options = {
      ...stocksChartOptions({ filename: `Intraday volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'MW',
          },
        },
      ],
      ...xAxisInUTC,
      series: [
        makeSeries('Optimised', this.state.id_volumes_optimised, {
          valueDecimals: 2,
        }),
        makeSeries('Base', this.state.id_volumes_base, { valueDecimals: 2 }),
      ],
    };
    const ib_options = {
      ...stocksChartOptions({ filename: `Imbalance volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'Values, MW',
          },
        },
        {
          title: {
            text: 'Difference, MW',
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: [
        makeSeries('Optimised', this.state.ib_volumes_optimised, {
          valueDecimals: 2,
        }),
        makeSeries('Base', this.state.ib_volumes_base, { valueDecimals: 2 }),
        makeSeries(
          'Difference',
          this.state.ib_volumes_optimised
            ? this.state.ib_volumes_optimised.map((val, index) => [
                val[0],
                val[1] - this.state.ib_volumes_base[index][1],
              ])
            : [],
          {
            valueDecimals: 2,
            yAxis: 1,
          }
        ),
      ],
    };

    const renderBatterySoC = () => {
      const options = {
        ...stocksChartOptions({ filename: `Battery SoC` }),
        yAxis: [
          {
            title: {
              text: 'Charge, MW',
            },
          },
        ],
        ...xAxisInUTC,
        series: [
          makeSeries('State of Charge', this.state.battery_soc, {
            valueDecimals: 2,
          }),
        ],
      };

      return (
        <Grid item xs={12}>
          <Card className={classes.bgColorAlt}>
            <CardHeader>
              <h4
                className={classNames(
                  classes.cardTitleWhite,
                  classes.textColor
                )}
              >
                Battery State of Charge
              </h4>
            </CardHeader>
            <CardBody className={classes.bgColorAlt}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      );
    };

    if (this.props?.isLoggedIn) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.sticky}>
            <Card className={!this.state.isOpen ? classes.hidden : ''}>
              <CardHeader
                color="primary"
                className={!this.state.isOpen ? classes.visible : ''}
              >
                <h4
                  style={{ position: 'relative' }}
                  className={classes.cardTitleWhite}
                >
                  Energy Delivery Days{' '}
                  <IconButton
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      display: 'inline-flex',
                      position: 'absolute',
                      right: '0',
                    }}
                    onClick={() =>
                      this.setState({ isOpen: !this.state.isOpen })
                    }
                  >
                    {this.state.isOpen ? (
                      <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                    )}
                  </IconButton>
                </h4>
              </CardHeader>
              {this.state.isOpen && (
                <CardBody>
                  <Grid container>
                    <Grid item xs={12}>
                      <form className={classes.container} noValidate>
                        <TextField
                          id="start_date"
                          label="Start date"
                          type="date"
                          defaultValue={this.state.start_date}
                          className={classNames(
                            classes.textField,
                            classes.spacing
                          )}
                          onChange={this.handleChange}
                          style={{ marginRight: '15px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <TextField
                          id="end_date"
                          label="End date"
                          type="date"
                          defaultValue={this.state.end_date}
                          className={classNames(
                            classes.textField,
                            classes.spacing
                          )}
                          onChange={this.handleChange}
                          style={{ marginRight: '15px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <FormControl
                          className={classNames(
                            classes.formControl,
                            classes.width300
                          )}
                          style={{ marginRight: '15px' }}
                        >
                          <ParkAutocomplete
                            id="portfolio"
                            label="Portfolio"
                            options={this.state.options}
                            selectedOption={activePark}
                            onChange={this.handleChange}
                          />
                        </FormControl>
                        <Button
                          className={classes.spacing}
                          color="primary"
                          round
                          onClick={this.start_build}
                          disabled={!this.props.markets.length}
                        >
                          Build
                        </Button>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.forceReCalculate}
                              onChange={this.handleChangeCheckbox}
                              name="forceReCalculate"
                              className={classes.checkboxAdnRadioStyle}
                            />
                          }
                          label="Force Recalculate"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="cashFlow"
                              onChange={this.handleChangeCheckbox}
                              checked={this.state.cashFlow}
                            />
                          }
                          label="Net pnl"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.nonActiveParks}
                              onChange={this.handleChangeNonActiveParks}
                              name="nonActiveParks"
                              className={classes.checkboxAdnRadioStyle}
                            />
                          }
                          label={
                            this.props.battery
                              ? 'Show non-active batteries'
                              : 'Show non-active parks'
                          }
                        />
                        <DownloadButton
                          data={this.state.tableData}
                          tables={document.getElementsByTagName('table')}
                          filename={
                            `PNL_Report_${parkName}` +
                            `_${this.state.start_date}_${this.state.end_date}`
                          }
                        />
                        <DownloadDetailedReport 
                          data={this.state.reportData}
                          filename={
                            `PNL_Detailed_Report_${parkName}` +
                            `_${this.state.start_date}_${this.state.end_date}`
                          }
                        />
                      </form>
                    </Grid>
                  </Grid>
                </CardBody>
              )}
            </Card>
          </Grid>
          {this.state.tableDataRender?.length &&
          this.state.tableHeader?.length &&
          this.state.tableYearDataWithTotalRender?.length &&
          this.state.tableHeaderYear?.length ? (
            <Grid item xs={12}>
              <Card>
                <CardBody>
                  <ProfitLossPeriod
                    tableHead={this.state.tableHeaderYear}
                    tableData={this.state.tableYearDataWithTotalRender}
                    parkId={this.state.portfolio.id}
                    winHeight={window.innerHeight}
                    isSkipIntraday={false}
                    marketId={marketId}
                    currency={this.state.currency}
                  />
                  <ProfitLossPeriod
                    tableHead={this.state.tableHeader}
                    tableData={this.state.tableDataRender}
                    parkId={this.state.portfolio.id}
                    winHeight={window.innerHeight}
                    isSkipIntraday={false}
                    marketId={marketId}
                    currency={this.state.currency}
                    battery={this.props.battery}
                  />
                </CardBody>
              </Card>
            </Grid>
          ) : null}

          {this.state.tableData.length > 0 ? (
            <>
              {this.props.battery && renderBatterySoC()}
              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Daily P&L Cumulative
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={optionsCumulative}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: "100%" }}>
                  <MaterialTable
                    className={classes.headerMatTable}
                    columns={helper.columns(this.state.currency, false)}
                    data={this.state.kpiRows}
                    title={"P&L KPI"}
                    options={{...helper.options(10), paging: false}}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Daily P&L
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={options}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Hourly P&L
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={hourly}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Day-ahead volumes
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={da_options}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Intraday volumes
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={id_options}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>

              <Grid item xs={12} ref={this.chartRef}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleWhite,
                        classes.textColor
                      )}
                    >
                      Imbalance volumes
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={ib_options}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
            </>
          ) : this.state.loading ? (
            <div className="loader" alt="Loading report..." />
          ) : null}
        </Grid>
      );
    } else {
      return <LoginPage />;
    }
  }
}

const Report = connect(mapStateToProps, mapDispatchToProps)(ProfitLossInterval);
export default withStyles(styles)(Report);
