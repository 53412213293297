import {
  UPDATE_MARKET_FILTER,
  UPDATE_VARIABLE_FILTER,
  UPDATE_SOURCE_FILTER,
  UPDATE_PERIOD_FILTER,
  UPDATE_AUDITOR_FILTER,
  LOADING_AUDIT_SUMMARY,
  UPDATE_IMPORTANCE_FILTER,
} from "constants/action-types";


const initialState = {
  selectedMarket: { name: 'All' },
  selectedVariable: { name: 'All' },
  selectedSource: 'All',
  selectedPeriod: { name: 'All' },
  selectedAuditor: 'All',
  loading: true,
  importance: 'All',
};


export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MARKET_FILTER:
      return {
        ...state,
        selectedMarket: action.payload,
      };
    case UPDATE_VARIABLE_FILTER:
  	  return {
        ...state,
        selectedVariable: action.payload,
      };
    case UPDATE_SOURCE_FILTER:
      return {
        ...state,
        selectedSource: action.payload,
      }
    case UPDATE_PERIOD_FILTER:
      return {
        ...state,
        selectedPeriod: action.payload,
      }
    case UPDATE_AUDITOR_FILTER: 
      return {
        ...state,
        selectedAuditor: action.payload,
      }
    case LOADING_AUDIT_SUMMARY:
      return {
        ...state,
        loading: action.payload,
      }
    case UPDATE_IMPORTANCE_FILTER: {
      return {
        ...state,
        importance: action.payload,
      }
    }
    default:
      return state;
  }
};
