import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from '@material-table/core';
import { FormControl, Grid, Chip, Checkbox, TablePagination, TextField } from '@material-ui/core';
import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod
  from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import moment from "moment";
import alertify from "alertifyjs";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import LoginPage from "views/Login/Oops.jsx";
import { security_fetch_params } from "actions/index";
import { primaryColor } from 'assets/jss/material-dashboard-react';
import { DATE_FORMAT_DASH } from "constants/general";
import { getMarketData } from "utils/getDataMethods";
import { stocksChartOptions, makeSeries, xAxisInUTC } from 'variables/charts';


const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    apiLabel: state.conn.label,
  };
};

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  fullWidth: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
};

const AuditDataDetails = (props) => {
  const [auditData, setAuditData] = useState(null);
  const [acknowledged, setAcknowledged] = useState('Not acknowledged');
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const [variableData, setVariableData] = useState(null);

  const { market: marketName,  market_id, variable_id, variable_name: variableName, variable} = props.location.state;
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsExportVisiblePeriod(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');

  const getMarketAuditData = async (pageNum, rowsPerPage, acknowledged) => {
    const acknowledgedStr = acknowledged === 'Acknowledged' 
      ? '&acknowledged=true' 
      : acknowledged === 'Not acknowledged'
        ? '&acknowledged=false'
        : '';

    const queryStr = `?page=${pageNum}&size=${rowsPerPage}${acknowledgedStr}&order_by=-time`;
    try {
      const response = await fetch(`https://kube.sama.energy/gateway/api/markets/${market_id}/variables/${variable_id}/audits${queryStr}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      });
  
      if (response.ok) {
        const data = await response.json();
        setAuditData(data);
      } else {
        alertify.error('Error getting data!', 5);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const updateMarketAuditData = async (audit_id, value) => {
    try {
      const response = await fetch(`https://kube.sama.energy/gateway/api/market_data_audit_entries/${audit_id}`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "acknowledged": value
        }),
        ...security_fetch_params,
      });
  
      if (response.ok) {
        const data = await response.json();
        
        if (data.acknowledged) {
          alertify.success(`Audit from ${moment(data.time).format("YYYY-MM-DD HH:mm:ss")} acknowledged!`)
        } else if (!data.acknowledged) {
          alertify.error(`Audit from ${moment(data.time).format("YYYY-MM-DD HH:mm:ss")} is not acknowledged!`)
        }

        await getMarketAuditData(page, pageSize, acknowledged);
      } else {
        alertify.error('Faild to update audit status!')
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getVariableData = async (market_id, variable, dateFrom, dateTo) => {
    const data = await getMarketData(
      market_id,
      variable,
      dateFrom,
      dateTo,
      props.apiLabel
    )
    setVariableData(data.data);
  }

  const handleChkChange = (e, value) => {
    updateMarketAuditData(e.target.id, value);
  }

  useEffect(() => {
      if (auditData) {
        let from = moment(auditData.items[0].time).unix();
        let to = moment(auditData.items[0].time).unix();
        const data = auditData.items.map((audit, index) => {
          if (moment(audit.time).unix() < from) {
            from = moment(audit.time).unix();
          } else if (moment(audit.time).unix() > to) {
            to = moment(audit.time).unix();   
          }
          return {
            id: index,
            created_at: moment(audit.created_at).format('YYYY-MM-DD HH:mm:ss'),
            updated_at: audit.updated_at ? moment(audit.updated_at).format('YYYY-MM-DD HH:mm:ss') : '-',
            auditor: audit.auditor,
            time: moment(audit.time).format('YYYY-MM-DD HH:mm:ss'),
            acknowledged: (
              <Checkbox
                color="primary"
                id={`${audit.id}`}
                onChange={handleChkChange}
                checked={audit.acknowledged}
                value=""
              />
            ),
          }
        });
  
        getVariableData(market_id, variable, moment.unix(from).add(-1, 'day'), moment.unix(to).add(1, 'day'));

        setDateFrom(moment.unix(from).add(-1, 'day'));
        setDateTo(moment.unix(to).add(1, 'day'));
        setTableData(data);
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData])

  useEffect(() => {
    getMarketAuditData(1, pageSize, acknowledged);

    setPage(1);
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledged]);

  const acknowledgedOptions = ['All', 'Acknowledged', 'Not acknowledged'];

  const tableHeads = ['Auditor', 'Time', 'Created at', 'Updated at', 'Acknowledged'];

  const makeColumns = (tableHeads) =>
    tableHeads.map(head => {
      return {
        title: head,
        field: head.toLowerCase().replace(' ', '_'),
        width: 110,
        sorting: false,
      }
    });
  
  const handleAuditsToShowSelect = (e, v) => {
    setAcknowledged(v);
  }

  const renderOptions = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        label={option}
        size="small"
        {...getTagProps({ index })}
      />
    ));

  const chartOptions = {
    ...stocksChartOptions({
      filename: `${marketName}, ${variableName} data audit`,
    }),
    ...xAxisInUTC,
    yAxis: [
      {
        title: {
          text: 'value',
        },
        min: null,
        max: null,
      },
      {
        title: {
          text: 'source timestamp, UTC',
        },
        type: 'datetime',
        opposite: true,
      },
    ],
    series: [
      ...(variableData
        ? variableData.map((graph, i) => {
            if (i === 1)
              return makeSeries(`${variableName} source time`, graph, {
                yAxis: 1,
                tooltip: {
                  // Note: Use regular function instead of arrow one
                  // to have TooltipFormatterContext as this
                  pointFormatter: function () {
                    return (
                      `<span style="color:${this.color}">\u25CF</span>` +
                      ' Source timestamp: <b>' +
                      moment.utc(this.y).format('Y-MM-DD HH:mm:SS') +
                      '</b><br/>'
                    );
                  },
                },
                dashStyle: 'Dot',
                visible: false,
              });
            return makeSeries(`${variableName}`, graph);
          })
        : []),
    ],
  };

  if (props.isLoggedIn) {
    return (
      <>
        <Card>
          <CardHeader color="primary">
            <h4 className={props.classes.cardTitleWhite}>{marketName}, {variableName} data audit</h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="acknowledged"
                    label="Audits to show"
                    options={acknowledgedOptions}
                    value={acknowledged}
                    getOptionLabel={(option) => option}
                    onChange={handleAuditsToShowSelect}
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {isLoading ? (
                  <div className="loader">Loading...</div>
                ) : (
                  <MaterialTable
                    columns={makeColumns(tableHeads)}
                    data={tableData}
                    onRowsPerPageChange={setPageSize}
                    options={{
                      toolbar: false,
                      tableLayout: 'fixed',
                      maxBodyHeight: window.innerHeight - 280,
                      headerStyle: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        color: primaryColor,
                        fontSize: '1em',
                        padding: '12px 0',
                        textAlign: 'center',
                        fontWeight: 500,
                      },
                      cellStyle: {
                        textAlign: 'center'
                      },
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [25, 50, 100],
                      pageSize: pageSize,
                      draggable: false,
                      search: false,
                    }}
                    components={{
                      Pagination: (props) => (
                        <TablePagination
                          rowsPerPage={pageSize} 
                          rowsPerPageOptions={[25, 50, 100]}
                          page={page - 1}
                          count={auditData.total}
                          onPageChange={(e, num) => {
                            setPage(num + 1);
                            getMarketAuditData(num + 1, pageSize, acknowledged);
                          }}
                          onRowsPerPageChange={(e) => {
                            setPageSize(e.target.value);
                            getMarketAuditData(page, e.target.value, acknowledged);
                          }}
                        />
                      )
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateFrom.format(DATE_FORMAT_DASH)}
                    onChange={(e) => setDateFrom(moment(e.target.value))}
                    inputProps={{
                      name: 'dateFrom',
                      id: 'dateFrom',
                    }}
                    label="From:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateTo.format(DATE_FORMAT_DASH)}
                    onChange={(e) => setDateTo(moment(e.target.value))}
                    inputProps={{
                      name: 'dateTo',
                      id: 'dateTo',
                    }}
                    label="To:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <Button 
                    color='primary' 
                    onClick={() => getVariableData(market_id, variable, dateFrom, dateTo)}
                  >
                    Refresh
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={chartOptions}
                  oneToOne={true}
                  immutable = {true}
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </>
    );
  } else{
    return (<LoginPage />);
  }
};

const ConnectedAuditDataDetails = connect(mapStateToProps)(AuditDataDetails);
export default withStyles(styles)(ConnectedAuditDataDetails);
