import React from 'react';
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Loading from '../Loading/Loading';
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';
import logo from 'assets/img/TISLogoBig.png';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import { 
  set_logged_in,
  set_uinfo,
  set_targets,
  buildAPIRequest,
  buildAccountRequest,
  buildKUBERequest,
  security_fetch_params,
  setLoginInstances } from "actions/index";

import { managePolicyRequest } from 'utils/auth';
import { APIS } from '../../constants/general';
import loginSprite from 'assets/img/login-sprite.png';

const styles = (theme) => ({
  grid: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  padding: {
    padding: theme.spacing(1),
  },
  button: {
    padding: "3px",
  },
  central: {
    margin: "0 auto",
    maxWidth: "460px",
    flexBasis: "100%",
  },
  buttonsContainer: {
    margin: "16px 0"
  },
  img: {
    width: "300px",
  },
  wide: {
    width: "100%",
  },
  imgContainer: {
    padding: "15px 16px 0",
    boxSizing: "border-box",
    width: "100%",
  },
  form: {
    padding: "15px 34px 30px",
    borderRadius: "10px",
    boxShadow: "0 5px 30px rgba(0, 0, 0, 0.52)",
    backgroundColor: "transparent",
  },
  font: {
    fontFamily: "Marcellus",
    fontWeight: "400",
    fontSize: "16px",
  },
  loginBtn: {
    width: "100%",
    padding: "10px 16px",
    marginTop: "8px",
    textTransform: "none",
    fontSize: "18px",
  },
  btn: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#5bc0de",
    border: "1px solid transparent",
    '&:hover' : {
      borderColor: '#46b8da',
      backgroundColor: "#31b0d5"
    }
  },
  declineBtn: {
    backgroundColor: "red",
    '&:hover': {
      backgroundColor: "red",
      borderColor: 'red'
    }
  },
  usernameField: {
    margin: "0",
    borderRadius: "5px 5px 0 0",
    borderTop: "1px solid #CCC",
    borderRight: "1px solid #CCC",
    borderLeft: "1px solid #CCC",
  },
  passwordField: {
    margin: "0",
    borderRadius: "0 0 5px 5px",
    borderTop: "1px solid #CCC",
    borderBottom: "1px solid #CCC",
    borderRight: "1px solid #CCC",
    borderLeft: "1px solid #CCC",
  },
  greyBackground: {
    backgroundColor: "#ededed",
  },
  userLoginIcon: {
    backgroundPosition: "7px 2px",
  },
  userPasswordIcon: {
    backgroundPosition: "7px -33px",
  },
  icon: {
    width: "30px",
    height: "30px",
    padding: "4px",
    backgroundImage:
      `url('${loginSprite}')`,
    backgroundRepeat: "no-repeat",
  },
  selectorContainer: {
    border: "1px solid #CCC",
    borderRadius: "5px",
  },
  input: {
    '&:-webkit-autofill': {
      backgroundColor: '#ededed !important',
      WebkitBoxShadow: "0 0 0 1000px #ededed inset"
    }
  },
  blockquote: {
    boxSizing: "border-box",
    maxWidth: "428px",
    margin: "0 auto",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    boxShadow: "0 5px 30px rgba(0, 0, 0, 0.62)",
    borderRadius: "10px",
    padding: "40px",
    color: "#3F484D",
    position: "relative",
    fontFamily: "'Lato', sans-serif",
    textAlign: "center",
    "&::before, &::after": {
      fontSize: "45px",
      color: "#3CA1D9",
      position: "absolute",
      height: "2px",
      left: "40px",
      right: "40px",
      lineHeight: ".5",
      background: "linear-gradient(to right, #3CA1D9 45%, transparent 45%, transparent), linear-gradient(to right, transparent, transparent 55%, #3CA1D9 55%)",
      fontFamily: "serif",
    },
    "&::before": {
      content: "'\u201C'",
      top: "30px",
    },
    "&::after": {
      content: "'\u201D'",
      bottom: "30px",
    },
    "& p": {
      fontSize: "14px",
    },
    "& footer": {
      marginBottom: "1em",
      padding: "0",
    },
    "& cite": {
      fontStyle: "normal",
      fontSize: "12px"
    },
  },
  nameLogo: {
    fontFamily: 'Marcellus, serif',
    textAlign: 'center',
    fontSize: '20px',
    color: '#2b328f',
  },

  asset: {
    color: '#ed2526',
  },

  supText: {
    fontSize: '12px',
  },
  blockquoteContainer: {
    minHeight: '253px'
  }
});

const mapStateToProps = state => {
  return {
    conn: state.conn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    set_logged_in: data => dispatch(set_logged_in(data)),
    set_uinfo: data => dispatch(set_uinfo(data)),
    set_targets: data => dispatch(set_targets(data)),
    setLoginInstances: data => dispatch(setLoginInstances(data)),
  };
}

class LoginTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      userTargets: [],
      ticket: '',
      advice: {},
    };

    this.on_proceed = this.on_proceed.bind(this);
    this.on_login = this.on_login.bind(this);
    this.on_change = this.on_change.bind(this);
    this.getUserTargets = this.getUserTargets.bind(this);
    this.on_cancel = this.on_cancel.bind(this);
  }

  componentDidMount() {
    // If data is already shifted like for P&L Pages (CET),
    // the 'UTC' timezone won't shift it more.
    // The user's timezone shouldn't be used anymore across the app.
    moment.tz.setDefault('UTC');
    localForage.clear();

    const getText = async () => {
      const text = await this.getAiText('I need a motivational or good work ethics quote on a trade or energy topic, up to 20 words with a positive context and the author credit');
      this.setState({advice: {content: text?.split('-')[0], author: text?.split('-')[1]}});
    }
    getText()
  }

  getUserTargets(userId) {
    const [authurl, authheaders] = buildAccountRequest(
      `/v1/user_target?user.id=${userId}`
    );
    fetch(authurl, {
      method: 'GET',
      headers: authheaders,
      ...security_fetch_params,
    })
      .then(
        function (r) {
          if (r.ok) {
            r.json().then(
              function (data) {
                this.props.set_targets(data.data);
                this.setState({ userTargets: data.data });
              }.bind(this)
            );
          }
        }.bind(this)
      )
      .catch((error) => {
        console.error('Account service login failed: fetch error: ', error);
      });
  }

  on_login() {
    const [authurl, authheaders] = buildAccountRequest('/account');
    const [kubeurl, kubeheaders] = buildKUBERequest('/api/user/login');

    const authform = JSON.stringify({
      login: this.state.username,
      password: this.state.password,
      target: 'swaccount',
    });
    
    fetch(kubeurl, {
      method: 'POST',
      headers: kubeheaders,
      ...security_fetch_params,
      body: JSON.stringify({
        login: this.state.username,
        password: this.state.password,
      }),
    })
      .then(
        (r) => {
          if (!r.ok) {
            alertify.error('Login failed', 5);
          }
        }
      ).catch(error => {
        console.error('Account service login failed: fetch error: ', error);
      })

      fetch('https://sama-k8s-dev.sama.energy/gateway/api/user/login', {
        method: 'POST',
        headers: kubeheaders,
        ...security_fetch_params,
        body: JSON.stringify({
          login: this.state.username,
          password: this.state.password,
        }),
      })
        .then(
          (r) => {
            if (!r.ok) {
              alertify.error('Login failed', 5);
            }
          }
        ).catch(error => {
          console.error('Account service login failed: fetch error: ', error);
        })

    fetch(authurl, {
      method: 'POST',
      headers: authheaders,
      ...security_fetch_params,
      body: authform,
    })
      .then(
        function (r) {
          if (r.ok) {
            r.json().then(
              function (data) {
                this.getUserTargets(data.data.user.id);
              }.bind(this)
            );
          } else {
            alertify.error('Login failed', 5);
          }
        }.bind(this)
      )
      .catch((error) => {
        console.error('Account service login failed: fetch error: ', error);
      });
  }

  on_proceed() {
    /*
     * login to the API target
     */
    const [url, headers, target] = buildAPIRequest('/login', 'urlencoded');

    const u = new URL(url);
    const n = `${u.protocol}//${u.hostname}:${u.port ? u.port : '443'}/`;

    const form = new URLSearchParams({
      login: this.state.username,
      password: this.state.password,
      next: n,
      ctx: 'oam',
    }).toString();

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: form,
      ...security_fetch_params,
    })
      .then(
        async function (r) {
          if (r.ok) {

            // Make multilogin only if showInstanceReport property is true
            if(this.props.conn.showInstanceReport) {
              const urlTargets = this.state.userTargets.map(target => target.url)
              const reportingAPI = Object.keys(APIS)
                .filter(api => APIS[api].reporting)
                .filter(api => urlTargets.includes(`${APIS[api].API_URL}/account`));
              const loginInstances = 
                reportingAPI.map(async api => {
                  const url = `${APIS[api].API_URL}/login`
                  const u = new URL(APIS[api].API_URL);
                  const n = `${u.protocol}//${u.hostname}:${u.port ? u.port : '443'}/`;
                  
                  const form = new URLSearchParams({
                    login: this.state.username,
                    password: this.state.password,
                    next: n,
                    ctx: 'oam',
                  }).toString();
                  let response;
                  try{
                    const timeout = 15000;
                    const controller = new AbortController();
                    const id = setTimeout(() => controller.abort(), timeout);
                  
                    response = await fetch(url, {
                      method: 'POST',
                      headers: headers,
                      body: form,
                      signal: controller.signal,
                      timeout,
                      ...security_fetch_params,
                    });

                    clearTimeout(id);
                  } catch (error) {
                    console.log(error)
                  }
                  if(response?.ok)
                    return {
                      'API_URL': APIS[api].API_URL,
                      'API_NAME': api,
                      'reporting': APIS[api].reporting,
                    };
                });
              const loginInstanceArr = (await Promise.all(loginInstances)).filter(el => el)
              const issueInstance = reportingAPI.filter(api => !loginInstanceArr.map(el => el.API_NAME).includes(api));
              // if there is an issue with the login, alert the user
              if(issueInstance.length > 0) {
                alertify.error(`For the instance report login failed for ${issueInstance.join(', ')}`, 5);
              }
              let loginInstanceObj = {};
              loginInstanceArr.forEach(instance => {
                loginInstanceObj[instance.API_NAME] = instance;
              })
              this.props.setLoginInstances(loginInstanceObj)
            }

            /*
             * login to the Samawatt Accounts Service if the API login was successful
             */
            const [authurl, authheaders] = buildAccountRequest('/account');
            const authform = JSON.stringify({
              login: this.state.username,
              password: this.state.password,
              target: target,
            });

            fetch(authurl, {
              method: 'POST',
              headers: authheaders,
              body: authform,
              ...security_fetch_params,
            })
              .then(managePolicyRequest)
              .catch((error) => {
                console.error(
                  'Account service login failed: fetch error: ',
                  error
                );
              });

            this.props.set_logged_in(true);
            sessionStorage.setItem('loggedIn', true);
          } else {
            this.props.set_logged_in(false);
            r.json().then(function (j) {
              const msg = j['args'][1];
              alertify.error('Login failed: ' + msg, 5);
            });
          }
        }.bind(this)
      )
      .catch((error) => {
        console.error('fetch failed j', error);
        alertify.error('Login failed: fetch error: ' + error, 5);
      });
  }

  on_cancel() {
    this.setState({userTargets: []});
    this.props.set_targets({});
  }

  on_change(e) {
    switch(e.target.id){
      case "username":
        this.setState({username: e.target.value});
        break;
      case "password":
        this.setState({password: e.target.value});
        break;
      default:
        break;
    }
  }

  getAiText = async function (prompt) {
    const endpoint = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      'Content-Type': 'application/json'
    };

    const requestBody = {
      model: "gpt-3.5-turbo",
      messages: [{role: "user", content: prompt}],
      max_tokens: 1000,
    };

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      return responseData.choices[0].message.content; 
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  render() {
    if (window.location.pathname === '/login') {
      return (
        <Grid container spacing={4}>
          <div className={this.props.classes.imgContainer}>
            <img
              src={logo}
              className={this.props.classes.img}
              alt="TIS Logo"
            ></img>
          </div>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            className={this.props.classes.central}
          >
            <Paper
              className={`${this.props.classes.padding} ${this.props.classes.form}`}
            >
              <Grid container justifyContent='center' style={{marginBottom: '15px', paddingLeft: '20px'}}>
                <Grid item>
                  <span className={this.props.classes.nameLogo}>
                    SAMA<span className={this.props.classes.asset}>Asset<sup className={this.props.classes.supText}>TM</sup></span>
                  </span>
                </Grid>
              </Grid>
              {this.state.userTargets.length > 0 ? (
                <div>
                  <HeaderLinks
                    dbgsrc="LoginForm"
                    pull_parks={false}
                    fontStyle={this.props.classes.font}
                    className={`${this.props.classes.selectorContainer} ${this.props.classes.greyBackground}`}
                  />
                  <div className={this.props.classes.buttonsContainer}>
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item className={this.props.classes.wide}>
                        <Button
                          onClick={this.on_proceed}
                          variant="outlined"
                          color="primary"
                          style={{ textTransform: 'none' }}
                          className={`${this.props.classes.button} ${this.props.classes.font} ${this.props.classes.btn}`}
                        >
                          Proceed
                        </Button>
                      </Grid>
                      <Grid item className={this.props.classes.wide}>
                        <Button
                          onClick={this.on_cancel}
                          variant="outlined"
                          color="primary"
                          style={{ textTransform: 'none' }}
                          className={`${this.props.classes.button} ${this.props.classes.font} ${this.props.classes.btn} ${this.props.classes.declineBtn}`}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div className={this.props.classes.margin}>
                  <Grid
                    container
                    alignItems="center"
                    className={`${this.props.classes.usernameField} ${this.props.classes.greyBackground}`}
                  >
                    <Grid item>
                      <div
                        className={`${this.props.classes.icon} ${this.props.classes.userLoginIcon}`}
                      ></div>
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <TextField
                        id="username"
                        label="Username"
                        type="text"
                        onChange={this.on_change}
                        value={this.state.username}
                        fullWidth
                        autoFocus
                        required
                        InputProps={{
                          className: this.props.classes.font,
                          disableUnderline: true,
                          inputProps: {
                            className: this.props.classes.input,
                            style: {
                              marginBottom: '16px',
                            },
                          },
                        }}
                        InputLabelProps={{
                          className: this.props.classes.font,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    className={`${this.props.classes.passwordField} ${this.props.classes.greyBackground}`}
                  >
                    <Grid item>
                      <div
                        className={`${this.props.classes.icon} ${this.props.classes.userPasswordIcon}`}
                      ></div>
                    </Grid>
                    <Grid item md={true} sm={true} xs={true}>
                      <TextField
                        id="password"
                        label="Password"
                        type="password"
                        onChange={this.on_change}
                        value={this.state.password}
                        fullWidth
                        required
                        InputProps={{
                          className: this.props.classes.font,
                          disableUnderline: true,
                          inputProps: {
                            className: this.props.classes.input,
                            style: {
                              marginBottom: '16px',
                            },
                          },
                        }}
                        InputLabelProps={{
                          className: this.props.classes.font,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Button
                      onClick={this.on_login}
                      variant="outlined"
                      color="primary"
                      className={`${this.props.classes.button} ${this.props.classes.font} ${this.props.classes.loginBtn} ${this.props.classes.btn}`}
                    >
                      Login
                    </Button>
                  </Grid>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} className={this.props.classes.blockquoteContainer}>
            <blockquote className={this.props.classes.blockquote}>
              {Object.keys(this.state.advice).length ? (
                <>
                  <p>{this.state.advice.content}</p>
                  <footer>
                    <cite>{this.state.advice.author}</cite>
                  </footer>
                </>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <Loading />
                </div>
              )}
            </blockquote>
          </Grid>
        </Grid>
      );
    } else {
      return (<a href="/login">Log Out</a>);
    }
  }
}

const DecoratedLoginTab = connect(mapStateToProps, mapDispatchToProps)(LoginTab);
export default withStyles(styles)(DecoratedLoginTab);
