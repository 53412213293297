import React, { useEffect, useState, useRef } from 'react';
import { FormControl, TextField, Grid, IconButton, Chip } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactJsonView from 'react-json-view';

import { get_markets_data, buildAPIRequest, security_fetch_params } from 'actions';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import 'moment-timezone';
import * as helper from './PNLAnalysisHelper';
import NoData from 'components/NoData';
import { STRATEGIES } from '../../constants/general';
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';

import localForage from "localforage";
import { logout } from "utils/auth";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import alertify from 'alertifyjs';

const styles = {
  ...checkboxAdnRadioStyle,
}

const mapStateToProps = (state) => {
  return {
    markets: state.markets.markets,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: () => dispatch(get_markets_data()),
});

const ParksJobDetails = (props) => {
  const [instances, setInstances] = useState({});
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [selectedParks, setSelectedParks] = useState([]);
  const [parks, setParks] = useState({});
  const [parkOptions, setParkOptions] = useState([]);
  const [parksData, setParksData] = useState(null);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [allMarkets, setAllMarkets] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [pageSize, setPageSize] = useState(50);

  const [jobsTableData, setJobsTableData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataToShow, setDataToShow] = useState({});
  const [selectedStrategies, setSelectedStrategies] = useState(['All'])
  const [strategies, setStrategies] = useState(null);
  
  const [parksFromStorage, setParksFromStorage] = useState(null);
  const [strategiesData, setStrategiesData] = useState(null);

  const { classes, getMarkets, markets, conn } = props;
  const initialRender = useRef(true);


  useEffect(() => {
    const storage = localStorage.getItem('jobParks')
      ? JSON.parse(localStorage.getItem('jobParks'))
      : {};
    setParksFromStorage(storage);
  }, []);

  const downloadParks = async (strategy = null, instanceUrl = null, nonActiveParks = false)  => { 
    const isAllParks = nonActiveParks ? '' : 'filter=active'
    const [currentInstanceUrl, headers] = buildAPIRequest(
      `/api/windparks?${isAllParks}`,
    );
    const strat = strategy ? `&strategy=${strategy}` : '';
    const url = instanceUrl ? `${instanceUrl}/api/windparks?${isAllParks}${strat}` : currentInstanceUrl;
    const localForgeParksString = !strategy ? `parks_${instanceUrl || currentInstanceUrl}${!nonActiveParks ? '_active' : ''}` : `parks_${instanceUrl || currentInstanceUrl}_${strategy}${!nonActiveParks ? '_active' : ''}`;
    
    const data = await localForage.getItem(localForgeParksString);
    
    if (!data) {
      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...security_fetch_params,
      });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
      }
      const resJson = await response.json();
      if (resJson.error) {
        alertify.error("Response error");
        console.log(resJson.error);
        return
      }
      resJson.updated_at = moment().unix();
      await localForage.setItem(localForgeParksString, resJson, (err) => {
        err && console.log(err);
      });
      
      return resJson;
    }
    
    return data;
  }

  const getParksAPIS = async () => {
    let parksObj = {};
    let stratsObj = {};
    const APIS = conn.loginInstances;

    const instList = Object.keys(APIS)
      .filter((api) => APIS[api].reporting);
    instList.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
    
    const allParks = instList.map(async (instance) => {
        const parksArr = await downloadParks(null, APIS[instance].API_URL);
        return parksArr ? parksArr.data : []
    });

    const allStrategies = instList.map(async (instance) => {
      const instStrategies = STRATEGIES.map(async (strategy) => {
        const parksArr = await downloadParks(strategy, APIS[instance].API_URL);
        return parksArr ? {[strategy]: parksArr.data.filter(park => !park.name.includes('test'))} : {[strategy]: []};
      })
      const stratArr = await Promise.all(instStrategies);

      let strategiesObj = {};
      stratArr.forEach(strategyObj => {
        strategiesObj = {...strategiesObj, ...strategyObj}
      })

      return {[instance]: strategiesObj};
    })
    
    const allStrategiesArr = await Promise.all(allStrategies);
    allStrategiesArr.forEach(instanceObj => {
      stratsObj = {...stratsObj, ...instanceObj}
    })

    const parkInstancesArr = await Promise.all(allParks);

    // sort parks in instance by name
    parkInstancesArr.forEach((parksArr, id) => {
      parkInstancesArr[id] = parksArr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    });

    instList.forEach((instance, id) => {
      parksObj[instance] = parkInstancesArr[id].filter(el => !el.name.includes('test'))
    })

    const instanceObj = {};
    Object.keys(parksObj).forEach((instance) => {
      instanceObj[instance] = {};
    })

    setStrategies(stratsObj);
    setInstances(instanceObj)
    setParks(parksObj)
  }

  const getParkModel = async (parkId, apiData) => {
    const localForageKey = `model_name_${parkId}_${apiData.label}`;

    const downloadModelName = async (parkId) => {
      const endpoint = `/api/v1/parks/${parkId}/model_name`;
      const [, headers] = buildAPIRequest(endpoint);
      const url = `${apiData.url}${endpoint}`
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
          ...security_fetch_params,
        });

        if (response.ok) {
          const data = await response.json();

          if (data.data) {
            data.updated_at = moment().unix();
            await localForage.setItem(localForageKey, data);
            return data.data; 
          } else if (data.error) {
            alertify.error(data.error, 'error', 5);
            return 'N/A';
          }
        } else if (response.status === 401) {
          logout();
          return;
        } else {
          return 'N/A';
        }
      } catch (error) {
        console.error("Error downloading model name:", error);
        return 'N/A';
      }
    };

    const cachedModel = await localForage.getItem(localForageKey);

    if (cachedModel) {
        if (cachedModel.updated_at < moment().unix() - 10000) {
            return await downloadModelName(parkId);
        } else {
            return cachedModel.data;
        }
    } else {
        return await downloadModelName(parkId);
    }
  };

  const getParkData = async (park_id, apiData) => {
    const INTERVAL = 86400;
    const urlString = `/api/v1/parks/${park_id}`;
    const localForgeVarString = `park_data_${park_id}_${apiData.label}`;
    const data = await localForage.getItem(localForgeVarString);
    if (!data || data.updated_at < moment().unix() - INTERVAL) {
      const [, headers] = buildAPIRequest(urlString);
      const url = `${apiData.url}${urlString}`
      const response = await fetch(url, {
        method: "GET",
        headers,
        ...security_fetch_params,
      });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
      }
      const resJson = await response.json();
      resJson.updated_at = moment().unix();
      if (!resJson.error) {
        await localForage.setItem(localForgeVarString, resJson, (err) => {
          err && console.log(err);
        });
      } else {
        alertify.error("Response error");
        console.log(resJson.error);
        return
      }
      return resJson.data;
    }
    else {
      return data.data
    }
  }

  const getParkOptimizationJob = async (park_id, api_data) => {
    const INTERVAL = 86400;
    const urlString = `/api/v1/parks/${park_id}/optimization_job`;
    const localForgeVarString = `optimization_job_${park_id}_${api_data.label}`;
    const data = await localForage.getItem(localForgeVarString);
    if (!data || data.updated_at < moment().unix() - INTERVAL) {
      const [, headers] = buildAPIRequest(urlString);
      const url = `${api_data.url}${urlString}`
      const response = await fetch(url, {
        method: "GET",
        headers,
        ...security_fetch_params,
      });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
      }
      const resJson = await response.json();
      resJson.updated_at = moment().unix();
      if (!resJson.error) {
        await localForage.setItem(localForgeVarString, resJson, (err) => {
          err && console.log(err);
        });
      } else {
        alertify.error("Response error");
        console.log(resJson.error);
        return
      }
      return resJson.data;
    }
    else {
      return data.data
    }
  }

  // Get markets and parks
  useEffect(() => {
    getMarkets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(Object.keys(conn.loginInstances).length)
      getParksAPIS();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[conn])

  useEffect(() => {
    if (
      parks && 
      strategies && 
      !strategiesData &&
      Object.keys(parks).length && 
      Object.keys(parks).length === Object.keys(strategies).length
    ) {
      let obj = {}

      Object.keys(parks).forEach(instance => {
        parks[instance].length
          ? parks[instance].forEach(park => {
            obj = {
              ...obj,
              [instance]: {
                ...obj[instance],
                [park.name]: { ...park, instance }
              }
            }
          })
          : obj = { ...obj, [instance]: [] }
      })
      
      if (Object.keys(obj).length === Object.keys(parks).length) {
        let stratObj = {};
        let objCopy = JSON.parse(JSON.stringify(obj));

        Object.keys(strategies).forEach(instance => {
          if(obj[instance]) {
            Object.keys(strategies[instance]).forEach((strategy) => {

              const parksForStrategy = strategies[instance][strategy].length
                ? strategies[instance][strategy].map((park) => {
                    delete objCopy[instance][park.name]
                    return obj[instance][park.name];
                  })
                : [];
              stratObj[strategy] = {
                ...stratObj[strategy],
                [instance]: parksForStrategy,
              };
            });
          }
        })

        let other = {};
        Object.keys(objCopy).forEach((instance) => {
          if (Object.keys(objCopy[instance]).length) {
            Object.keys(objCopy[instance]).forEach((parkName) => {
              other[instance] =
                other[instance] && other[instance].length
                  ? [...other[instance], objCopy[instance][parkName]]
                  : [objCopy[instance][parkName]];
            });
          } else {
            other[instance] = [];
          }
        });
        
        stratObj['Other'] = {...other};

        setStrategiesData(stratObj);
      }
    }
  }, [parks, strategies, strategiesData])

  useEffect(() => {
    if (Object.keys(parks).length && selectedInstances.length && selectedInstances.every(el => Object.keys(parks).includes(el))) {
      const parksArr = selectedInstances
        .map((instance) => {
          if (!parks[instance].length) {
            alertify.error(`No parks for ${instance}!`);
          };

          return parks[instance].map((park) => ({
            name: `${instance}: ${park.name}`,
            id: park.id,
            park: park.name,
            instance,
            market: park.market,
          }));
        })
        .flat()
        .filter(park => !park.name.includes('test'));
      
      setParkOptions(parksArr);
      setIsLoading(true)

      if(parksFromStorage && Object.keys(parksFromStorage).length) {
        const selected = selectedInstances
        .map((instance) => {
          if (parksFromStorage[instance]) {
            return parksFromStorage[instance].map((park) => ({
              name: `${instance}: ${park.name}`,
              id: park.id,
              park: park.name,
              instance,
            }));
          } else {
            return parks[instance]
          }
        })
        .flat();

        setSelectedParks(parksArr.filter(park => selected.find(el => el.id === park.id && el.instance === park.instance)));
      } else {
        setSelectedParks(parksArr);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parks, selectedInstances]);

  useEffect(() => {
    if(parksFromStorage && Object.keys(parksFromStorage).length) {
      const instanceFromStorage = Object.keys(parksFromStorage);
      setSelectedInstances(instanceFromStorage);
    }
  },[parksFromStorage])

  useEffect(() => {
    if (markets.length) {
      const sortedMarkets = markets.filter(el => el.parent_id).map(market => market.name).sort((a,b) => a > b ? 1 : -1)
      setAllMarkets(sortedMarkets);
      setSelectedMarkets(sortedMarkets);
    }
  }, [markets])

  useEffect(() => {
    if (
      selectedInstances.length &&
      selectedStrategies.length &&
      strategiesData &&
      parksData &&
      selectedParks.length === parksData.length
    ) {
      const filteredParks = Object.keys(strategiesData)
        .filter(strategy => {
          if (selectedStrategies.includes('All')) return strategy;

          return selectedStrategies.includes(strategy);
        })
        .map(strategy => {
          return Object.keys(strategiesData[strategy]).map(
            instance => strategiesData[strategy][instance].length ? strategiesData[strategy][instance] : []
          ).flat()
        })
        .flat()
        .filter(park => selectedInstances.includes(park.instance))
        .filter(park => selectedMarkets.includes(park.market))
        .filter(park => selectedParks.find(el => el.id === park.id && el.instance === park.instance));
      
      let dataArr = [];
      
      if (filteredParks.length) {
        filteredParks.forEach(async (park, index) => {
          const { API_URL, API_NAME } = conn.loginInstances[park.instance];
          const model = await getParkModel(park.id, {
            label: API_NAME,
            url: API_URL,
          });
          const parkData = parksData.find(parkData => parkData.id === park.id && parkData.instance === park.instance);
          const limit = parkData.data.optimization_job.entry_over && Object.keys(parkData.data.optimization_job.entry_over).length && typeof parkData.data.optimization_job.entry_over['0'].Pmax === 'number' ? parkData.data.optimization_job.entry_over['0'].Pmax : parkData.data.optimization_job.Pmax 
          
          dataArr.push([park.instance, park.market, park.name, limit, model, `${park.id}`]);
          if (dataArr.length === filteredParks.length) {
            dataArr = dataArr
            .sort((a, b) => (a[2] > b[2] ? 1 : -1))
            .sort((a, b) => (a[0] > b[0] ? 1 : -1));
  
            setJobsTableData(dataArr);
            setIsLoading(false);
          }
        });
      } else {
        setJobsTableData(dataArr);
        setIsLoading(false);
      }

      if (initialRender.current) initialRender.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstances, parksData, selectedMarkets, selectedStrategies, strategiesData]);

  useEffect(() => {
    if (selectedParks && selectedParks.length) {
      const getData = async () => {
        const parksData = await Promise.all(selectedParks.map(async (park) => {
          const { API_URL, API_NAME } = conn.loginInstances[park.instance];
          const optJob = await getParkOptimizationJob(park.id, { label: API_NAME, url: API_URL});
          const data = await getParkData(park.id, { label: API_NAME, url: API_URL});
          return {optimization_job: optJob, instance: park.instance, id: park.id, name: park.name, data: data};
        }))
        
        setParksData(parksData.filter(park => park));
      }
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParks]);

  const handleInstanceChange = (e, v) => {
    
    let obj = {};
    v.forEach(instance => {
      const arr = parksFromStorage[instance] ? parksFromStorage[instance] : parks[instance];
      obj = {...obj, [instance]: arr}
    })

    const sortedInstances = [...v].sort((a,b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1); 
    setSelectedInstances(sortedInstances);
    if (!v.length) setSelectedParks([]);

    localStorage.setItem('jobParks', JSON.stringify(obj));
  };

  const handleParkChange = (e, v) => {

    let obj = {};
    Object.keys(parks).forEach(instance => {
      const arr = parks[instance].filter(park => v.map(park => park.name).includes(`${instance}: ${park.name}`));
      if(arr.length)
        obj = {...obj, [instance]: arr};
    })
    
    // unique instances in selected parks
    const uniqueInstances = v.map(park => park.instance).filter((val,ind,arr) => arr.indexOf(val) === ind);
    if(uniqueInstances.length !== selectedInstances.length) {
      setSelectedInstances(uniqueInstances);
    }

    const sortedParks = [...v].sort((a,b) => {
      const splitA = a.name.split(':');
      const splitB = b.name.split(':');
      const comparison = splitA[0].localeCompare(splitB[0]);
      return comparison ? -1 : 1;
    });
    
    setSelectedParks(sortedParks);

    if(uniqueInstances.length !== selectedInstances.length) {
      setSelectedInstances(uniqueInstances);
    }
 
    localStorage.setItem('jobParks', JSON.stringify(obj));
    
  };

  const handleMarketChange = ((e, v) => {
    const sortedMarkets = [...v].sort((a,b) => a > b ? 1 : -1)
    setSelectedMarkets(sortedMarkets)
  })

  const handleStrategiesChange = (e, v) => {
    if (e.target.textContent === 'All') {
      setSelectedStrategies(['All']);
    } else if (v.includes('All')) {
      setSelectedStrategies(v.slice(1));
    } else if (v.length === strategiesOptions.length - 1) {
      setSelectedStrategies(['All']);
    } else {
      setSelectedStrategies(v);
    }
  };

  const handleCloseModal = () => setModalIsOpen(false);

  const handleOpenModal = (id, instance) => {
    const parkData = parksData.find(park => `${park.id}` === id && instance === park.instance);
    setDataToShow({data: parkData.optimization_job, name: parkData.name});
    setModalIsOpen(true);
  };

  const strategiesOptions = [
    ...['All', 'Other'],
    ...helper.STRATEGIES,
  ];

  const jobsTableHead = ['Instance', 'Market', 'Park', 'Limit', 'Model Name'];

  const makeColumn = (headsArr) => {
    return headsArr.map((head, index) => {
      if (head === 'Model Name') {
        return {
          title: head,
          field: head.toLowerCase(),
          width: 200,
          sorting: true,
          draggable: true,
          render: row => <div key={`${row.park_id.replace(/\s/g,'')}${row.instance}`} style={{cursor: 'pointer'}} onClick={() => handleOpenModal(row.park_id.replace(/\s/g,''), row.instance)}>{row['model name']}</div>
        }
      } else if (index === 0 || index === 1) {
        return {
          title: head,
          field: head.toLowerCase(),
          width: 100,
          sorting: true,
          draggable: true,
          ...(index === 1 ? {} : {defaultGroupOrder: 0})
        }
      }
      return {
        title: head,
        field: head.toLowerCase(),
        width: 100,
        sorting: true,
        draggable: false,
      };
    });
  };

  const makeRows = (headsArr, dataArr) => {
    const rows = dataArr.map((rowArr, index) => {
      const obj = {};
      rowArr.forEach((data, index) => (typeof data === 'number' ? obj[headsArr[index].toLowerCase()] = Number(data.toFixed(2)).toLocaleString() : obj[headsArr[index].toLowerCase()] = data));
      obj['id'] = index;
      return obj;
    });
    return rows;
  };

  const renderTable = (tableHead, tableData) => {
    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontSize: '1em',
      fontWeight: 500,
    };
    return (
      <Grid item xs={12}>
        <Card>
          <CardBody>
            <div className={classes.tableResponsive}>
              <MaterialTable
                columns={makeColumn(tableHead)}
                data={makeRows([...tableHead, 'park_id'], tableData)}
                onRowsPerPageChange={setPageSize}
                options={{
                  showTitle: false,
                  paging: true,
                  maxBodyHeight: window.innerHeight - 280,
                  headerStyle: headerStyle,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [10, 25, 50, 100],
                  pageSize: pageSize,
                  grouping: true,
                  defaultExpanded: true,
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Grid>
    )
  }

  const content = () => {
    if (
      !initialRender.current &&
      ((selectedInstances.length && selectedParks && !selectedParks.length) || 
      !selectedMarkets.length || 
      !jobsTableData.length || 
      !selectedStrategies.length)
    ) {
      return (
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardBody className={classes.bgColor}>
            <NoData />
          </CardBody>
        </Card>
      )
    }
    else if (
      !isLoading &&
      selectedInstances.length &&
      jobsTableData.length &&
      parksData
    ) {
      return (
        <>
          <Card >
            <CardHeader color="primary">
              Parks
            </CardHeader>
            <CardBody >
              {renderTable(jobsTableHead, jobsTableData)};
            </CardBody>
          </Card>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modalIsOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={modalIsOpen}>
            <Box style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: "50%",
              overflow: 'scroll',
              height: '50%',
              backgroundColor: '#fff',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              <ReactJsonView src={dataToShow.data} displayDataTypes={false} collapsed={1} name={dataToShow.name}/>
              </Box>
            </Fade>
          </Modal>
        </>
      );
    } else {
      return <div className="loader" alt="Loading report..." />;
    }
  }; 

  const renderOptions = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        label={option}
        size="small"
        {...getTagProps({ index })}
      />
    ));

  const handleDoubleClick = (e) => {
    if (selectedMarkets.length >= Math.floor(allMarkets.length / 2)) {
      setSelectedMarkets([])
    } else {
      setSelectedMarkets(allMarkets)
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.stickyHead}>
        <Card className={!isOpen ? classes.hidden : ""}>
          <CardHeader color="primary" className={!isOpen ? classes.visible : ""}>
            <h4
              className={classes.cardTitleWhite}
              style={{ position: 'relative' }}
            >
              Report settings
              <IconButton
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  display: 'inline-flex',
                  position: 'absolute',
                  right: '0',
                }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                )}
              </IconButton>
            </h4>
          </CardHeader>
          <CardBody style={!isOpen ? { display: 'none' } : undefined}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} lg={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    multiple
                    id="instances"
                    options={Object.keys(instances)}
                    value={selectedInstances}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Instance" />
                    )}
                    onChange={handleInstanceChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    classes={{inputRoot: classes.autocompleteMaxHeight}}
                    disabled={selectedInstances.length ? false : true}
                    multiple
                    id="parks"
                    limitTags={2}
                    options={parkOptions}
                    value={selectedParks}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Park" />
                    )}
                    onChange={handleParkChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    classes={{inputRoot: classes.autocompleteMaxHeight}}
                    multiple
                    id="markets"
                    options={allMarkets}
                    value={selectedMarkets}
                    limitTags={5}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          onDoubleClick={handleDoubleClick}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Markets to show" />
                    )}
                    onChange={handleMarketChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <Autocomplete
                    multiple
                    id="strategies"
                    options={strategiesOptions}
                    value={selectedStrategies}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderTags={renderOptions}
                    renderInput={(params) => (
                      <TextField {...params} label="Strategies" />
                    )}
                    onChange={handleStrategiesChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.container}>
          {selectedInstances.length ? content() : null}
        </div>
      </Grid>
    </Grid>
  );
};

const ConnectedParksJobDetails = connect(mapStateToProps, mapDispatchToProps)(ParksJobDetails);
export default withStyles(helper.styles, tableStyle, styles)(ConnectedParksJobDetails);
